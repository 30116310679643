.index_search_11de {
  color: rgba(15, 21, 31, 1)  !important
}

.index_search_11de::-webkit-input-placeholder {
    color: rgba(15, 21, 31, 0.35) !important;
  }

.index_search_11de::-moz-placeholder {
    color: rgba(15, 21, 31, 0.35) !important;
  }

.index_search_11de:-ms-input-placeholder {
    color: rgba(15, 21, 31, 0.35) !important;
  }

.index_search_11de::placeholder {
    color: rgba(15, 21, 31, 0.35) !important;
  }

@media only screen and (max-width: 800px) {
  .index_container_1b0d {
    display: flex;
    padding: 0 10px;
    width: 100%;
  }

  .index_search_11de {
    font-size: 1rem !important;
    margin-bottom: 2px !important;
    height: 22px !important;
    color: black
  }
  
    .index_search_11de::-webkit-input-placeholder {
      font-size: 0.9rem !important;
    }
  
    .index_search_11de::-moz-placeholder {
      font-size: 0.9rem !important;
    }
  
    .index_search_11de:-ms-input-placeholder {
      font-size: 0.9rem !important;
    }
  
    .index_search_11de::placeholder {
      font-size: 0.9rem !important;
    }

  .index_addonAfter_47e3 {
    padding-right: 0px !important;
  }
}

@media only screen and (min-width: 801px) {
  .index_container_1b0d {
    display: flex;
    width: 100%;
    margin-right: 26px;
    margin-top: 4px;
  }
}

  .index_addonAfter_47e3 {
    padding-right: 5px;
    font-size: 1em !important;
  }

.index_inputContainer_1e08 {
  flex: 1;
  border-bottom: none !important;
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}

.index_spinner_c058 {
  width: 1.5em !important;
}

.index_clearSearch_90a9 {
  cursor: pointer;
  width: 1em
}

.index_clearSearch_90a9 g {
    stroke: #606060;
  }

