.ChecklistBox_checklist_9963 {
  box-sizing: border-box;
  width: 400px;
  margin-bottom: 30px;
  padding: 40px;
  color: #121821;
  background-color: #ffffff
}

.ChecklistBox_checklist_9963.ChecklistBox_onRight_2df6 {
    float: right;
    margin-left: 30px;
  }

.ChecklistBox_checklist_9963.ChecklistBox_onLeft_43a0 {
    float: left;
    margin-right: 30px;
  }

.ChecklistBox_checklist_9963 > .ChecklistBox_title_be4d {
    margin-bottom: 30px;
    font-family: 'Nexa Bold', sans-serif;
  }

.ChecklistBox_checklist_9963 > .ChecklistBox_list_e4aa {
    list-style: none
  }

.ChecklistBox_checklist_9963 > .ChecklistBox_list_e4aa > .ChecklistBox_listItem_e427 {
      display: flex;
      align-items: center;
      margin-bottom: 30px
    }

.ChecklistBox_checklist_9963 > .ChecklistBox_list_e4aa > .ChecklistBox_listItem_e427 > .ChecklistBox_checkIcon_06da {
        margin-right: 14px;
      }

@media only screen and (max-width: 800px) {
  .ChecklistBox_checklist_9963 {
    width: 100%
  }

    .ChecklistBox_checklist_9963.ChecklistBox_onRight_2df6 {
      margin-left: 0;
    }

    .ChecklistBox_checklist_9963.ChecklistBox_onLeft_43a0 {
      margin-right: 0;
    }
}
