@media only screen and (max-width: 801px) {
  .index_locationListItem_98e5 {
    flex-wrap: wrap
  }

    .index_locationListItem_98e5 a {
      width: 100%;
    }

  .index_advertiserInfoContainer_404e {
    padding: 0 0 10px 10px;
  }
}

.index_contentContainer_9e10 {
  overflow-y: scroll;
}

.index_modalContainer_6a9b {
  height: 100%;
  padding-bottom: 50px
}

.index_modalContainer_6a9b h2,
  .index_modalContainer_6a9b h3 {
    font-weight: 500;
  }

.index_closeIcon_3d60 {
  width: 35px !important;
}

.index_button_b51a {
  padding: 10px 34px 11px 34px !important;
}

.index_locationsList_b3af {
  list-style-type: none;
}

.index_locationListItem_98e5 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 14px;
  padding-top: 17px;
  border-bottom: 1px solid #3f4753
}

.index_locationListItem_98e5 p {
    font-size: 16px;
  }

.index_locationListItem_98e5 h3 {
    line-height: 30px;
    font-size: 22px;
    margin-bottom: 7px;
  }

.index_locationsHeading_04d4 {
  margin-top: 13vh;
  text-align: center;
  margin-bottom: 8vh
}

.index_locationsHeading_04d4 h2 {
    line-height: 30px;
    font-size: 32px;
    margin-bottom: 25px;
  }

.index_locationsHeading_04d4 p {
    font-size: 20px;
    line-height: 24px;
  }

.index_modalLayoutMiddle_ab93 {
  display: flex;
  justify-content: center;
}
