.SearchTermList_container_bf7d {
  display: block;
  position: absolute;
  background-color: white;
  left: calc(50% + 7px);
  top: 125px;
  padding: 20px;
  width: 50%;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
}

.SearchTermList_container_bf7d ul {
  list-style-type: none;
  margin: 5px 0 5px 0;
}

.SearchTermList_container_bf7d li {
  margin: 7px 0 5px 0;
}

.SearchTermList_container_bf7d .SearchTermList_noTerm_728f {
  margin: 7px 0 10px 0;
}

.SearchTermList_container_bf7d li:hover {
  cursor: pointer;
}

.SearchTermList_container_bf7d .SearchTermList_close_20a5 {
  width: 19px;
  margin-left: auto;
  margin-top: -35px;
}

.SearchTermList_container_bf7d .SearchTermList_title_aa2a {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.SearchTermList_container_bf7d .SearchTermList_clear_9748 {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
}

.SearchTermList_container_bf7d .SearchTermList_close_20a5:hover {
  width: 17px;
  margin-top: -33px;
}

@media only screen and (max-width: 800px) {
  .SearchTermList_container_bf7d {
    left: 0;
    top: 65px;
    color: black;
    width: 100%;
  }
}