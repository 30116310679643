html {
  scroll-behavior: smooth;
}

@media only screen and (min-width: 801px) {
  .ListPage_middle_0b5c {
    margin: 135px auto 0 auto;
  }

  .ListPage_categoriesContainer_4fe5 {
    height: 40px;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
  }

  .ListPage_categoriesDropdowns_31ae {
    width: 600px;
    margin-left: 15px;
    color: black;
    display: flex;
    justify-content: flex-start
  }
    .ListPage_categoriesDropdowns_31ae > div {
      margin-right: 0;
    }

  .ListPage_sortDropDown_25ac {
    width: 300px !important;
    justify-content: left
  }

    .ListPage_sortDropDown_25ac > div {
      width: 130px !important;
    }

  .ListPage_contentContainer_4672 {
    max-width: 1168px;
    width: 100vw;
    height: 480px;
    display: flex;
    flex-direction: column;
  }

  .ListPage_pageThreeArticleCarouselContainer_d63a {
    height: 100%; /* Needs to be the same as Carousel component's .articleBox CSS class */
    width: 100%;
    padding: 0px 10px;
  }

  .ListPage_divider_ba41 {
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
  }

  .ListPage_dividerHeading_223f {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 12px;
  }
  
  .ListPage_dividerText_2a36 {
    font-size: 18px;
    line-height: 21px;
  }

  .ListPage_carusellTitle_99d9 {
    margin: 10px 0px;
  }
  
}

@media only screen and (max-width: 800px) {
  .ListPage_middle_0b5c {
    margin-top: 65px;
    position: relative;
  }

  .ListPage_categoriesContainer_4fe5 {
    position: relative;
    overflow-y: auto;
    height: 100%;
    max-height: 550px;
  }

  .ListPage_categoriesDropdowns_31ae {
    color: black;
    display: flex;
    justify-content: flex-start;
    flex-direction: column
  }
    .ListPage_categoriesDropdowns_31ae > div {
      margin-right: 0;
    }

  .ListPage_mobileContent_d329 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; 
   }

   .ListPage_whenSearchingOrSorting_29d1 {
    margin-top: -65px !important;
   }
   
   .ListPage_mobileArticleCarouselContainer_152d {
     height: 37vh; /* Needs to be the same as Carousel component's .articleBox CSS class */
     width: 100vw;
   }
   
   .ListPage_mobileDivider_866f {
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
     background-color: #121821;
     height: 55px;
   }
}

.ListPage_container_6b47 {
  background: #f2f2f2;
}

.ListPage_searchContainer_d6f9 {
  display: flex;
  justify-content: flex-end;
  color: black;
  width: 600px;
}

.ListPage_theList_c81f {
  min-height: calc(100vh - 100px);
  background-color: #f2f2f2;
}

.ListPage_theList_c81f.ListPage_cordova_d7b3 {
  margin-bottom: 50px;
  margin-bottom: calc(50px + constant(safe-area-inset-bottom)); /* iOS 11.0 */
  margin-bottom: calc(50px + env(safe-area-inset-bottom)); /* iOS 11.2 */
  background-color: #121821;
}

.ListPage_offersButton_5b63 {
  padding: 12px;
  margin-top: 14px;
}

.ListPage_blur_d2d2 {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  pointer-events: none;
}