.PasswordInput_showOrHide_6f8f {
  cursor: pointer;
  display: block;
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
