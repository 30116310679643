.index_h1_0b03 {
  margin-top: 43px;
  margin-bottom: 19px;
}

.index_terms_4b51 {
  margin-top: 3em;
  margin-bottom: 3em;
}

.index_selectorContainer_c97e {
  display: flex;
  align-items: center;
  min-width: 100px;
  font-size: 13px;
  padding-left: 20px;
}

.index_buttonContainer_b3f2 {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  margin-bottom: 90px;
}

.index_onboardingHeader_b751 {
  height: 40px;
}
