@media only screen and (max-width: 800px) {
  .index_container_c754 {
    padding: 0 20px;
    border-bottom: 1px solid #363f4c;
    height: 65px;
  }
  .index_loginIcon_5929 {
    width: 1.5em !important;
  }
  .index_iconLogo_a117 {
    height: 22px;
    width: 67px;
    margin: auto;
  }

  .index_notificationIcon_5cd3 {
    height: 34px;
    width: 34px;
  }

  .index_notification_0e1c {
    color: white;
    text-decoration: none;
    position: relative;
    display: inline-block;
    line-height: 22px;
    border-radius: 2px;
  }

  .index_notification_0e1c:active {
    background-color: #e3e9f3;
    border-radius: 25px;
  }

  .index_notification_0e1c .index_badge_b12c {
    position: absolute;
    top: 1px;
    right: -4px;
    width: 21px;
    height: 21px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 50%;
    background: #fd1e6e;
    color: white;
    text-align: center;
  }
  

  .index_left_c2f2 {
    flex: 1;
  }
  .index_right_a0f2 {
    flex: 1;
  }
  .index_middle_0cd1 {
    flex: 1;
  }

  .index_searchAndFilterContainer_e1ac {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .index_searchIcon_6b49 {
    font-size: 2.5em !important;
    border-width: 0.05em !important
  }
    .index_searchIcon_6b49::before {
      width: 0.26em !important;
      height: 0.06em !important;
      right: -0.18em !important;
      bottom: -0.1em !important;
    }

  .index_filterIconMobile_0716 {
    margin-left: 15px;
  }

  .index_searchBarWidthCordova_e671 {
    width: 65vw;
  }

  .index_searchBarWidthMobileBrowser_938e {
    width: 50vw;
  }

  .index_searchContainer_6d76 {
    display: flex;
    color: white;
    min-width: 180px;
    max-width: 300px;
    background-color: white;
    border-radius: 20px;
  }

  .index_searchIconMobile_5f6c {
    margin-left: 15px;
    min-height: 28px;
  }

  .index_searchIconCordova_5545 {
    margin-right: 5px;
  }

  .index_filterIconCordova_7559 {
    margin-right: 15px
  }
}

@media only screen and (min-width: 801px) {
  .index_container_c754 {
    padding: 0 32px;
    height: 85px;
  }
  .index_iconLogo_a117 {
    height: 29px;
    width: 90px;
  }
  .index_left_c2f2 {
    flex: 3;
  }
  .index_right_a0f2 {
    flex: initial;
  }
  .index_middle_0cd1 {
    flex: 0;
  }
}

.index_wrapper_0292 {
  background-color: #121821;
  display: flex;
  flex: 1;
}

.index_container_c754 {
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.index_container_c754 .index_betaFlag_9db2 {
  color: #fff;
  font-size: 7pt;
  position: relative;
  top: -4px;
  left: 2px;
  text-transform: lowercase;
}

.index_left_c2f2 {
  display: flex;
  align-items: center;
}

.index_rightContent_32b3 {
  display: flex;
}

.index_languageSelector_1400 {
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-left: 30px;
  flex: initial;
  align-self: center;
  font-size: 16px;
  font-weight: 400;
  width: 45px
}

.index_languageSelector_1400::before {
    display: none !important;
  }

.index_languageSelector_1400 .Select-value {
    padding-right: 0px !important;
  }

.index_languageSelector_1400 .Select-arrow-zone {
    width: auto;
    width: initial;
    padding-right: 0px !important;
  }

.index_languageSelector_1400 .Select-arrow {
    border-color: #fff transparent transparent;
  }

.index_loginIcon_5929 {
  margin: auto 0;
  width: 2em;
}

.index_middle_0cd1 {
  display: flex;
  align-items: center;
}

.index_right_a0f2 {
  display: flex;
}

.index_menuButton_098a {
  width: 100%;
}

.index_iconDiscounts_b4e1 {
  height: 23px;
  width: 17px;
}

.index_iconProfile_ab2f {
  height: 21.21px;
  width: 22px;
}

.index_iconFilter_9f2a {
  height: 27.21px;
  width: 28px;
}

.index_selectedIconFilter_8df0 path {
    fill: #fd1e6e;
  }

.index_linkWrapper_77fa + .index_linkWrapper_77fa {
    margin-left: 40px;
  }

.index_active_2ccc > .index_linkContent_8d2f {
  /* @todo svg icon */
  color: #fd1e6e
}

.index_active_2ccc > .index_linkContent_8d2f polygon,
  .index_active_2ccc > .index_linkContent_8d2f path {
    fill: #fd1e6e;
  }

.index_linkContent_8d2f {
  font-size: 14px;

  display: flex;
  flex-direction: row;
  text-transform: uppercase
}

.index_linkContent_8d2f > *:not(:first-child) {
    margin-left: 20px;
  }

.index_center_306f {
  margin: auto;
}

.index_iconMenu_23a3 {
  width: 35px;
  height: 25px;
  margin-right: 15px;
}

.index_iconProfile_ab2f {
  width: 26px;
  height: 27px;
}

.index_questionMarkContainer_0812 {
  width: 30px;
  height: 30px;
  flex: 1;
  border-radius: 50%;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.index_questionMarkBtn_476b {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}
