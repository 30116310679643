.GenericModal_modalBackdrop_f4bb {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  opacity: 0.98;
}

.GenericModal_modalBackdropDark_2513 {
  background: #121821;
}

.GenericModal_modalBackdropLight_d4f4 {
  background-color: #f6f6f6;
}

.GenericModal_container_c262 {
  z-index: 4;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
