.FeaturesMobile_carousel_4932 {
  background-color: #121821;
}

.FeaturesMobile_slideContainer_29bc {
  min-height: 480px;
  padding-bottom: 0;
}

.FeaturesMobile_slideIndicatorContainer_6c9f {
  height: 64px;
}

.FeaturesMobile_button_65d0 {
  margin-top: 20px;
}

.FeaturesMobile_slideIndicator_4f4d {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  width: 1.3em;
}
