.InitiateAccountDeletion_smallMarginTop_e60d {
  margin-top: 0.5em;
}

.InitiateAccountDeletion_marginTop_ea76 {
  margin-top: 1em;
}

.InitiateAccountDeletion_marginBottom_2e23{
  margin-bottom: 80px;
}

.InitiateAccountDeletion_menuButton_1325 {
  border: 2px solid #fd1e6e !important;
  color: #fd1e6e !important;
}

.InitiateAccountDeletion_header_5b2d {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.InitiateAccountDeletion_p_36d5 {
  margin-top: 1em;
  margin-bottom: 25px;
}