.index_check_01e0 {
  width: 146px;
  height: 140px;
  margin-bottom: 2em;
}

.index_message_1a3f {
  margin-top: 1em;
}

.index_button_3438 {
  margin-top: 90px;
  margin-bottom: 10px;
}
