@media only screen and (max-width: 800px) {
  .ProfileSettingsPage_heroImage_4268 {
    background-color: #282d34;
    margin-top: 65px;
    height: 40vh;
  }

  .ProfileSettingsPage_profileHeader_e8f3 {
    justify-content: center;
    margin-bottom: 2em;
  }

  .ProfileSettingsPage_profilePicWrapper_d9c3 {
    justify-content: center;
    flex-direction: column;
    align-items: center !important
  }
    .ProfileSettingsPage_profilePicWrapper_d9c3 > p {
      margin: 0.6em 0 0 0 !important;
    }

  .ProfileSettingsPage_bigFooterLogo_ca39 {
    width: 150px !important;
  }

  .ProfileSettingsPage_middle_aff5 {
    box-sizing: border-box;
    padding: 0 1.5em;
  }
}

.ProfileSettingsPage_textSection_5bcd {
  border-bottom: 1px solid #a9a9a9;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.ProfileSettingsPage_textSectionHeader_aff2 {
  color: #a9a9a9;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.ProfileSettingsPage_textSectionParagraph_7ee5 {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
}

.ProfileSettingsPage_middle_aff5 form > div:last-child {
  margin-bottom: 0;
}

.ProfileSettingsPage_slideContainer_c113 {
  padding-bottom: 0;
}

.ProfileSettingsPage_slideHeader_1101 {
  display: flex;
  max-width: 80%;
  margin: auto;
  justify-content: center;
  align-items: center
}

.ProfileSettingsPage_slideHeader_1101 > :first-child {
    max-width: 55px;
    flex: 0 0 100%;
    padding-right: 20px;
  }

.ProfileSettingsPage_slideIndicatorContainer_5b37 {
  text-align: center;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ProfileSettingsPage_slideIndicator_28be {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  width: 1.3em;
}

.ProfileSettingsPage_discountsBanner_b000 {
  height: 50px;
  line-height: 50px;
  color: #121821;
  background-color: #feed01;
}

.ProfileSettingsPage_footer_d269 {
  height: 215px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.ProfileSettingsPage_profileHeader_e8f3 {
  display: flex;
  margin-top: 5.5em;
  margin-bottom: 2em;
}

.ProfileSettingsPage_profilePicWrapper_d9c3 {
  display: flex;
  align-items: flex-end
}

.ProfileSettingsPage_profilePicWrapper_d9c3 > .ProfileSettingsPage_profilePicElement_017c {
    border-radius: 4em;
    width: 7em;
    height: 7em;
  }

.ProfileSettingsPage_profilePicWrapper_d9c3 > p {
    margin-bottom: 0.4em;
    margin-left: 0.8em;
    font-size: 1.3em;
  }

.ProfileSettingsPage_profilePicPlaceholder_2878 {
  background: #feed01;
  opacity: 0.1;
}

.ProfileSettingsPage_formContainer_9cf2 {
  padding: 0 32px;
}

.ProfileSettingsPage_profileSettingsForm_3d62 input::-webkit-input-placeholder {
      color: white;
    }

.ProfileSettingsPage_profileSettingsForm_3d62 input::-moz-placeholder {
      color: white;
    }

.ProfileSettingsPage_profileSettingsForm_3d62 input:-ms-input-placeholder {
      color: white;
    }

.ProfileSettingsPage_profileSettingsForm_3d62 input::placeholder {
      color: white;
    }

.ProfileSettingsPage_profileSettingsForm_3d62 input:disabled {
      color: #757575;
    }

.ProfileSettingsPage_profileSettingsForm_3d62 label {
    color: #a9a9a9;
    font-weight: normal;
  }

.ProfileSettingsPage_fixedHeader_d9ce {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.ProfileSettingsPage_top_67c8 {
  flex-direction: column;
  height: auto;
}

.ProfileSettingsPage_bottom_f1f4 {
  height: 30vh;
  display: flex;
  align-items: center;
}

.ProfileSettingsPage_heroImage_4268 {
  background-color: #282d34;
  margin-top: 65px;
  height: 40vh;
}

.ProfileSettingsPage_bottom_f1f4 .ProfileSettingsPage_bigFooterLogo_ca39 {
  width: 200px;
}

.ProfileSettingsPage_languageSelect_e5ac {
  margin-bottom: 0;
}

.ProfileSettingsPage_verifyButtons_4c1d {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

@media only screen and (max-width: 600px) {
  .ProfileSettingsPage_verifyButtons_4c1d {
    grid-template-columns: 1fr;
  }
}

.ProfileSettingsPage_content_2405 {
  max-width: 700px;
}

.ProfileSettingsPage_formSectionCaption_ce93 {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.ProfileSettingsPage_verifyButtons_4c1d button {
  margin-left: 0;
  margin-right: 0;
}

.ProfileSettingsPage_addEducationButtonContainer_cdd6 {
  padding-top: 0.2em;
  padding-bottom: 1em;
}
