@media only screen and (min-width: 801px) {
  .GenericButton_container_760d {
    width: auto
  }
    .GenericButton_container_760d.GenericButton_expand_6f85 {
      width: 313px;
    }
    .GenericButton_container_760d.GenericButton_full_0dbb {
      width: 100%;
    }
}

@media only screen and (max-width: 800px) {
  .GenericButton_container_760d {
    display: block;
    width: 100%;
  }
}

.GenericButton_container_760d.GenericButton_outline_0c0a,
.GenericButton_container_760d.GenericButton_blackoutline_6929,
.GenericButton_container_760d.GenericButton_bold_52f6 {
  font-family: 'Nexa Bold', sans-serif;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 19px;
  box-sizing: border-box;
  padding: 20px 20px;
  position: relative;
  will-change: transform;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  border-radius: 50px
}

.GenericButton_container_760d.GenericButton_outline_0c0a:not(.GenericButton_disabled_1443):active, .GenericButton_container_760d.GenericButton_blackoutline_6929:not(.GenericButton_disabled_1443):active, .GenericButton_container_760d.GenericButton_bold_52f6:not(.GenericButton_disabled_1443):active {
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
  }

.GenericButton_container_760d.GenericButton_outline_0c0a.GenericButton_hasTopMargin_ce81, .GenericButton_container_760d.GenericButton_blackoutline_6929.GenericButton_hasTopMargin_ce81, .GenericButton_container_760d.GenericButton_bold_52f6.GenericButton_hasTopMargin_ce81 {
    margin-top: 20px;
  }

.GenericButton_container_760d.GenericButton_outline_0c0a.GenericButton_surroundMargin_7973, .GenericButton_container_760d.GenericButton_blackoutline_6929.GenericButton_surroundMargin_7973, .GenericButton_container_760d.GenericButton_bold_52f6.GenericButton_surroundMargin_7973 {
    margin: 5px;
  }

.GenericButton_container_760d.GenericButton_outline_0c0a.GenericButton_externalLink_6489, .GenericButton_container_760d.GenericButton_blackoutline_6929.GenericButton_externalLink_6489, .GenericButton_container_760d.GenericButton_bold_52f6.GenericButton_externalLink_6489 {
    display: flex;
    align-items: center;
    justify-content: center
  }

.GenericButton_container_760d.GenericButton_outline_0c0a.GenericButton_externalLink_6489:hover, .GenericButton_container_760d.GenericButton_blackoutline_6929.GenericButton_externalLink_6489:hover, .GenericButton_container_760d.GenericButton_bold_52f6.GenericButton_externalLink_6489:hover {
      text-decoration: underline;
    }

.GenericButton_container_760d.GenericButton_outline_0c0a.GenericButton_externalLink_6489 .GenericButton_externalLinkIcon_1668, .GenericButton_container_760d.GenericButton_blackoutline_6929.GenericButton_externalLink_6489 .GenericButton_externalLinkIcon_1668, .GenericButton_container_760d.GenericButton_bold_52f6.GenericButton_externalLink_6489 .GenericButton_externalLinkIcon_1668 {
      margin-left: 10px;
    }

.GenericButton_container_760d.GenericButton_subtle_92b0 {
  font-size: 13px;
  text-align: center;
  line-height: 24px;
  color: #fd1e6e;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.GenericButton_container_760d.GenericButton_outline_0c0a {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.GenericButton_container_760d.GenericButton_blackoutline_6929 {
  border: 2px solid #121821;
  color: #121821;
  background-color: #ffffff;
}

.GenericButton_container_760d.GenericButton_small_bf53 {
  padding: 12px;
}

.GenericButton_container_760d.GenericButton_bold_52f6 {
  background-color: #ffffff;
  color: #121821
}

.GenericButton_container_760d.GenericButton_bold_52f6.GenericButton_disabled_1443 {
    background: grey;
    cursor: not-allowed;
  }

.GenericButton_iconWrapper_b276 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3.3em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 11px;
}
