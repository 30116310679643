.SignUpPage_checkboxContainer_d9ee {
  margin: 25px 0;
}

.SignUpPage_h1_c4a4 {
  margin-top: 43px;
  margin-bottom: 19px;
}

.SignUpPage_p_bfd1 {
  margin-bottom: 25px;
}

.SignUpPage_bottom_ff1b {
  margin-top: 1em;
  margin-bottom: 1em;
}

.SignUpPage_checkboxLabel_18e6 {
  font-size: inherit;
}

.SignUpPage_hidden_80d9 {
  display: none;
}
