.index_container_3e93 {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.index_logoContainer_9c23 {
  width: 80%;
  position: relative;
  top: -4%;
}
