.IsicLinkDialog_menuWrapper_b58a {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 50px;
  background-color: #121821;
  font-size: 24px;
  text-align: center;
  cursor: default;
  z-index: 20;
}

.IsicLinkDialog_menu_9631 {
  margin-top: 20vh;
  width: 100%
}

.IsicLinkDialog_menu_9631 > * {
    width: 100%;
    margin-top: 20px;
  }

.IsicLinkDialog_label_8b35 {
  margin-bottom: 30px;
}
