@media only screen and (min-width: 1025px) {
  .Carousel_slideHeading_36a4 {
    text-align: center;
  }
}

@media only screen and (min-width: 801px) {
    .Carousel_mobileMode_f22f .Carousel_slideHeading_36a4 {
      line-height: 50px !important;
      font-size: 45px !important;
    }

  .Carousel_slideSubtitle_70ce {
    font-size: 23px !important;
    line-height: 30px !important;
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 1500px) {
  div.Carousel_arrowButton_6731.Carousel_next_a980 {
    right: 17px;
  }
}

.Carousel_boxStack_9a35,
.Carousel_portraitSlide_c07a {
  height: 100%;
  width: 96%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.Carousel_boxStack_9a35 {
  justify-content: space-between;
}

.Carousel_portraitSlide_c07a {
  background: #eaeaea !important;
  justify-content: flex-end;
  overflow: hidden;
  position: relative
}

.Carousel_portraitSlide_c07a > img {
    height: 100%;
    align-self: center;
  }

.Carousel_mobilePlaceholder_d447 {
  height: 60vw;
}

.Carousel_slideHeading_36a4 {
  font-size: 22px;
  line-height: 27px;
  padding: 0 10px;
}

.Carousel_cell_db89 {
  height: 48.5% !important;
}

.Carousel_portraitOverlay_4cba {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(253, 30, 110, 0.8);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Carousel_slideTextContainer_fe7c {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
}

.Carousel_tintOverlay_a757 {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(18, 24, 33, 0.37);
}

.Carousel_mobileMode_f22f {
  width: 100%;
  background-color: inherit !important
}

.Carousel_mobileMode_f22f .Carousel_slideTextContainer_fe7c {
    max-width: 70%;
    left: 15%;
  }

.Carousel_mobileMode_f22f .Carousel_slideHeading_36a4 {
    align-self: flex-start;
    padding: 0;
    line-height: 37px;
    font-size: 30px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

.Carousel_slideSubtitle_70ce {
  align-self: flex-start;
  margin-top: 5px;
  text-shadow:
  -1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
   1px 1px 0 #000;
}

.Carousel_arrowButton_6731 {
  position: absolute;
  top: 43%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 30px;
  height: 62px;
  width: 62px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  color: #fd1e6e;
  cursor: pointer
}

.Carousel_arrowButton_6731.Carousel_next_a980 {
    right: -16px;
  }

.Carousel_arrowButton_6731.Carousel_prev_4499 {
    left: -28px;
  }

.Carousel_arrowButton_6731:active {
    height: 60px;
    width: 60px;
  }

.Carousel_arrowButton_6731.Carousel_tiny_f614 {
    width: 30px;
    height: 30px
  }

.Carousel_arrowButton_6731.Carousel_tiny_f614.Carousel_next_a980 {
      right: 5px;
    }

.Carousel_arrowButton_6731.Carousel_tiny_f614.Carousel_prev_4499 {
      left: 5px;
    }

.Carousel_articleBox_2ca1 {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  height: 37vh;
  position: relative;
  overflow: hidden;
  background-color: #eaeaea;
}

@media only screen and (min-width: 801px) {
  .Carousel_articleBox_2ca1 {
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: #eaeaea;
  }
}