
  .index_container_6d74 a {
    text-decoration: underline;
  }

.index_container_6d74 {
  white-space: pre-wrap;
}

.index_bold_990b {
  font-weight: 400;
}

.index_bolder_3bcb {
  font-weight: 500;
}

.index_small_d4cb {
  font-size: 16px;
}

.index_xSmall_62b6 {
  font-size: 14px;
}

.index_normal_95bf {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.index_subtle_dddc {
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
}

.index_black_bfd6 {
  color: #121821;
}

.index_question_196b {
  font-size: 20px;
}

.index_centerText_c4ba {
  text-align: center;
}