.TextInput_container_d7aa {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: none;
  justify-content: flex-start
}

.TextInput_container_d7aa:not(.TextInput_box_ce22) {
    margin-bottom: 5px;
    margin-top: 5px
  }

.TextInput_container_d7aa:not(.TextInput_box_ce22) .TextInput_input_f423 {
      margin-bottom: 4px;
      height: 20px;
      font-size: 17px;
      color: #ffffff;
      width: 70%;
    }

.TextInput_container_d7aa.TextInput_box_ce22 {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 11px;
    padding-left: 15px
  }

.TextInput_container_d7aa.TextInput_box_ce22 .TextInput_input_f423 {
      height: 60px;
      font-size: 15px;
      color: #000;
    }

.TextInput_topRow_d567 {
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: flex-start;
}

@media only screen and (max-width: 800px) {
.TextInput_inputWrapper_da06 {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #a9a9a9
}

  .TextInput_inputWrapper_da06:before {
    display: block;
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
  } 

  .TextInput_inputWrapper_da06:after {
    display: block;
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    will-change: transform;
    transition: -webkit-transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0, 1.01, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

  .TextInput_inputWrapper_da06.TextInput_inFocus_3aa0:after {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  .TextInput_inputWrapper_da06 > .TextInput_addonAfter_3e2a {
    padding-left: 1em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    font-size: 13px;
    color: #898989;
    margin-top: 3px;
  }

.TextInput_search_da1b  {
  border-bottom: 0px solid #a9a9a9 !important;
}
}

@media only screen and (min-width: 800px) {

  .TextInput_inputWrapper_da06 {
    display: flex;
    justify-content: space-between;
    position: relative
  }
  
    .TextInput_inputWrapper_da06:before {
      display: block;
      content: ' ';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background: #a9a9a9;
    } 
  
    .TextInput_inputWrapper_da06:after {
      display: block;
      content: ' ';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background: #fd1e6e;
      -webkit-transform: scale(0, 1);
              transform: scale(0, 1);
      will-change: transform;
      transition: -webkit-transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
      transition: transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
      transition: transform 0.3s cubic-bezier(0, 1.01, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
      -webkit-transform-origin: top left;
              transform-origin: top left;
    }
  
    .TextInput_inputWrapper_da06.TextInput_inFocus_3aa0:after {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    .TextInput_inputWrapper_da06 > .TextInput_addonAfter_3e2a {
      padding-left: 1em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      font-size: 13px;
      color: #898989;
    }
  }

.TextInput_errorMessage_8161 {
  font-size: 13px;
  font-weight: normal;
  color: #fd1e6e;
  margin-top: 6px;
}

.TextInput_label_30bb {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #a9a9a9;
  margin-bottom: 13px;
}

.TextInput_input_f423 {
  font-family: inherit;
  flex-grow: 1;
  font-weight: normal;
  line-height: 20px;
}

.TextInput_input_f423::-webkit-input-placeholder {
  font-weight: 500;
  color: #6a6a6a;
}

.TextInput_input_f423::-moz-placeholder {
  font-weight: 500;
  color: #6a6a6a;
}

.TextInput_input_f423:-ms-input-placeholder {
  font-weight: 500;
  color: #6a6a6a;
}

.TextInput_input_f423::placeholder {
  font-weight: 500;
  color: #6a6a6a;
}
