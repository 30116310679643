.ArticleContainer_article_6936 {
  padding: 0 40px;
  margin-bottom: 40px;
  line-height: normal;
  font-size: 18px
}

.ArticleContainer_article_6936 .ArticleContainer_body_8f8c {
    color: #121821;
    font-weight: 300;
    line-height: 29px
  }

.ArticleContainer_article_6936 .ArticleContainer_body_8f8c h2 {
      margin: 20px 0;
    }

.ArticleContainer_article_6936 .ArticleContainer_body_8f8c h3 {
      margin: 20px 0;
    }

.ArticleContainer_article_6936 .ArticleContainer_body_8f8c p:not(:first-child) {
      margin: 20px 0;
    }

.ArticleContainer_article_6936 .ArticleContainer_body_8f8c ul,
    .ArticleContainer_article_6936 .ArticleContainer_body_8f8c ol {
      padding-left: 18px;
    }

.ArticleContainer_article_6936 a {
    color: #fd1e6e;
    text-decoration: underline;
  }

.ArticleContainer_date_33e7 {
  color: #a0a0a0;
  font-size: 14px;
}

h1.ArticleContainer_articleTitle_af2f {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 42px;
  line-height: 52px;
}

@media only screen and (max-width: 800px) {
  h1.ArticleContainer_articleTitle_af2f {
    font-size: 32px;
    line-height: 32px;
  }
}
