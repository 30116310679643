.OfferItem_container_fe09 {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.OfferItem_container_fe09.OfferItem_cell_4228 {
    border-radius: 8px;
  }

.OfferItem_image_2de2 {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover
}

.OfferItem_image_2de2:after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: ' ';
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.98) 100%
    );
  }

.OfferItem_container_fe09.OfferItem_cell_4228 .OfferItem_image_2de2:after {
  /*background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.6) 80%);*/
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.OfferItem_info_c1db {
  position: absolute;
  bottom: 22px;
  left: 0;
  right: 0;
  z-index: 2;
}

.OfferItem_title_3863 {
  margin: 95px 24px 0;
  /*font-family: "Nexa Bold";*/
  font-size: 22px;
  line-height: 18px;
  font-weight: 500;
  text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}

.OfferItem_description_c8d3 {
  margin: 12px 24px 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}

.OfferItem_voucherTag_a477 {
  position: absolute;
  right: 0;
  top: 20px;
  width: 100px;
  z-index: 2;
}

.OfferItem_star_4c7d {
  position: absolute;
  opacity: 0.85;
  top: 5px;
  left: 5px;
  padding: 5px 5px 10px 5px;
  z-index: 3;
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  -webkit-tap-highlight-color: transparent !important;
}

.OfferItem_tooltip_e5fa {
  z-index: 3;
  position: absolute;
  display: inline-block;
}

.OfferItem_tooltip_e5fa .OfferItem_tooltiptext_5a00 {
  width: 100px;
  visibility: hidden;
  background-color: rgb(235, 235, 235);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  font-size: 14px;

  /* Position the tooltip */
  position: absolute;
  top: 10px;
  left: 48px;
  z-index: 3;
}

.OfferItem_tooltip_e5fa .OfferItem_tooltiptext_5a00::before {
  content: " ";
  position: absolute;
  bottom: 78%;  /* At the top of the tooltip */
  left: -10%;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgb(235, 235, 235) transparent;
}