@media only screen and (max-height: 650px) {
  .ProfilePhotoPage_imageWrapperButtonRow_956f {
    margin-top: 1em !important;
  }
}

.ProfilePhotoPage_instructionsText_c8ea {
  margin-bottom: 2em;
  max-width: 300px;
}

.ProfilePhotoPage_profilePhotoBottom_1328 {
  margin-bottom: 30px;
}

.ProfilePhotoPage_justify-center_bfe9 {
  justify-content: center;
}

.ProfilePhotoPage_width-100_5804 {
  width: 100%;
}

.ProfilePhotoPage_modalContainer_8e83 {
  height: 100%;
  padding-bottom: 50px;
}

.ProfilePhotoPage_textContentWrapper_e306 p {
  font-size: 15px;
  font-weight: 300;
}

.ProfilePhotoPage_modalLayoutMiddle_0e7f {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfilePhotoPage_requirementsList_be07 {
  list-style-type: none
}

.ProfilePhotoPage_requirementsList_be07 li {
    display: flex;
    align-items: center;
    line-height: 28px
  }

.ProfilePhotoPage_requirementsList_be07 li p {
      max-width: 300;
    }

.ProfilePhotoPage_listItemCheck_ce5e {
  margin-right: 10px;
}

.ProfilePhotoPage_imageContentWrapper_7d63 .ProfilePhotoPage_pictureIcon_8b65 {
  width: 3em
}

.ProfilePhotoPage_imageContentWrapper_7d63 .ProfilePhotoPage_pictureIcon_8b65 g {
    stroke: white;
  }

.ProfilePhotoPage_uploadImageTxtCordova_19a3 {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.5em;
  white-space: nowrap
}

.ProfilePhotoPage_uploadImageTxtCordova_19a3 button {
    margin: 0 0.3em 0 0.3em;
  }

.ProfilePhotoPage_imageContentWrapper_7d63 .ProfilePhotoPage_uploadImageTxt_d06e {
  margin-top: 0.5em;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center
}

.ProfilePhotoPage_imageContentWrapper_7d63 .ProfilePhotoPage_uploadImageTxt_d06e p {
    color: #fd1e6e;
    margin-left: 0.5em;
  }

.ProfilePhotoPage_uploadImageTxt_d06e .ProfilePhotoPage_uploadImageIcon_31cf {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 0.7em
}

.ProfilePhotoPage_uploadImageTxt_d06e .ProfilePhotoPage_uploadImageIcon_31cf g {
    stroke-width: 0.2em;
  }

.ProfilePhotoPage_uploadNewImageIcon_0f19 {
  margin-right: 0.5em;
}

.ProfilePhotoPage_rotateImageLink_cb40 {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer
}

.ProfilePhotoPage_rotateImageLink_cb40 p {
    font-size: 14px;
  }

.ProfilePhotoPage_imageContentMobile_626a {
  margin: 0 auto;
}

.ProfilePhotoPage_imageContentWrapper_7d63 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em
}

.ProfilePhotoPage_imageContentWrapper_7d63 p {
    font-size: 14px;
    text-align: center;
  }

.ProfilePhotoPage_imageContentWrapper_7d63 > p {
    margin-top: 1em;
  }

.ProfilePhotoPage_dropZone_3054 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  border: 2px dashed #fd1e6e;
  padding: 5px
}

.ProfilePhotoPage_dropZone_3054 p {
    margin-top: 0.5em;
  }

.ProfilePhotoPage_imageWrapperButtonRow_956f {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
}

.ProfilePhotoPage_imageWrapperButtonRow_956f button {
  min-width: 200px;
}

.ProfilePhotoPage_bottomRow_f283 {
  flex: none;
  padding-top: 2em;
  border-top: 1px solid #272e39
}

.ProfilePhotoPage_bottomRow_f283 button {
    min-width: 150px;
  }

.ProfilePhotoPage_middleRow_2bcf {
  margin-bottom: 2em
}

.ProfilePhotoPage_middleRow_2bcf > input {
    display: none;
  }
