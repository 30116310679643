.StudentCardView_white_f5c5 {
  background-color: white !important;
  color: black;
}

.StudentCardView_blue_c7c1 {
  background-color: #498edf !important;
  color: white;
}

.StudentCardView_green_505c {
  background-color: #37bb9d !important;
  color: white;
}

.StudentCardView_header_a183 {
  font-size: 12pt;
  min-height: 10px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  margin: 5px 0;
}

.StudentCardView_studentCard_2a63 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}

.StudentCardView_detailsContainer_17c9 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.StudentCardView_studentInfoContainer_7a76 {
  padding-top: 18px;
  padding-left: 24px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: 300px;
}

.StudentCardView_studentInfoContainerIos_7a6a {
  margin-bottom: 70px;
}

.StudentCardView_name_6cd4 {
  font-size: 22px;
  line-height: 23px;
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.StudentCardView_details_dc60 {
  font-size: 17px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.StudentCardView_studentCardDetailsContainer_b782 {
  margin-top: 20px;
}

.StudentCardView_studentCardOrganizationDetails_58d3 {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
