.notificationFeed_containerOpen_3390 {
  position: fixed;
  z-index: 4;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  -webkit-animation: notificationFeed_slideIn_c3b1 0.5s forwards;
  animation: notificationFeed_slideIn_c3b1 0.5s forwards;
}

.notificationFeed_containerClose_fcb4 {
  position: fixed;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  -webkit-animation: notificationFeed_slideOut_1220 0.5s forwards;
  animation: notificationFeed_slideOut_1220 0.5s forwards;
}

.notificationFeed_notificationDrawerOpen_e239 {
  position: fixed;
  background-color: rgba(18, 24, 33, 0.85);
  right: -100vw;
  width: 300px;
  height: calc(100vh - 97px - calc(45px + constant(safe-area-inset-bottom))); /* iOS 11.0 */
  height: calc(100vh - 97px - calc(45px + env(safe-area-inset-bottom))); /* iOS 11.2 */
  height: calc(100vh - 97px - calc(17px)); /* takes effect on Android */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  -webkit-animation: notificationFeed_slideIn_c3b1 0.5s forwards;
  animation: notificationFeed_slideIn_c3b1 0.5s forwards;
}

@-webkit-keyframes notificationFeed_slideIn_c3b1 {
    100% { right: 0% }
}

@keyframes notificationFeed_slideIn_c3b1 {
    100% { right: 0% }
}

.notificationFeed_notificationDrawerClose_fd97 {
  position: fixed;
  background-color: rgba(18, 24, 33, 0.85);
  right: 0%;
  width: 300px;
  height: calc(100vh - 97px - calc(45px + constant(safe-area-inset-bottom))); /* iOS 11.0 */
  height: calc(100vh - 97px - calc(45px + env(safe-area-inset-bottom))); /* iOS 11.2 */
  height: calc(100vh - 97px - calc(17px)); /* takes effect on Android */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  -webkit-animation: notificationFeed_slideOut_1220 0.5s forwards;
  animation: notificationFeed_slideOut_1220 0.5s forwards;
}

@-webkit-keyframes notificationFeed_slideOut_1220 {
    100% { right: -100vw}
}

@keyframes notificationFeed_slideOut_1220 {
    100% { right: -100vw}
}

.notificationFeed_notificationContainer_a1de {
  background-color: white;
  margin: 5px 10px 5px 10px;
  padding: 10px;
  border-radius: 2px;
  word-wrap: break-word;
  word-wrap: break-word;
}

.notificationFeed_noMessages_5fec {
  margin: 0 auto
}

.notificationFeed_time_fd89 {
  color: #FD1E6D;
  margin-bottom: 10px;
}

.notificationFeed_link_4182 {
  color: blue;
  text-decoration: underline;
}