.StudentCardButton_studentCardButton_cad9 {
  position: absolute;
  height: 50px;
  width: 50px;
  border: 2px solid white;
  border-radius: 100%;
  background-color: white;
}

.StudentCardButton_blue_7828 {
  border: 2px solid white;
}

.StudentCardButton_green_4407 {
  border: 2px solid white;
}

.StudentCardButton_white_959a {
  border: 1px solid #e3e3e3;
}

.StudentCardButton_studentCardButton_cad9 img {
  max-height: 100%;
  max-width: 100%;
}

.StudentCardButton_pulseContainer_20da {
  z-index: 1;
  -webkit-animation: StudentCardButton_pulse2_124d 1.3s infinite ease-in-out;
          animation: StudentCardButton_pulse2_124d 1.3s infinite ease-in-out;
  will-change: transform;
}

.StudentCardButton_pulse_4d80 {
  height: 100%;
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 100%;
  background-color: white;
  border-color: white;
}

.StudentCardButton_pulse_4d80:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f1f442;
  border-radius: 100%;
  z-index: -1;
  -webkit-animation: StudentCardButton_pulse_4d80 1.3s infinite;
          animation: StudentCardButton_pulse_4d80 1.3s infinite;
  will-change: transform;
}

@-webkit-keyframes StudentCardButton_pulse2_124d {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  70% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes StudentCardButton_pulse2_124d {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  70% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes StudentCardButton_pulse_4d80 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes StudentCardButton_pulse_4d80 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}
