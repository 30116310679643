.QrCodeView_qrCodeContainer_8037 {
  margin-left: auto;
  margin-right: auto;
}

.QrCodeView_whiteBorderQrContainer_712b {
  border: 2px solid #ffffff;
  border-radius: 22px;
  padding: 10px;
  margin: 0 auto 25px auto;
}

.QrCodeView_blackBorderQrContainer_373d {
  border: 2px solid #000000;
  border-radius: 22px;
  padding: 10px;
  margin: 0 auto 25px auto;
}

.QrCodeView_innerContainer_360a {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.QrCodeView_periodContainer_4863 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.QrCodeView_period_0afa {
  display: inline-block;
  font-size: 48px;
  line-height: 48px;
  font-weight: bold;
}

.QrCodeView_validUntilContainer_e1bd {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.QrCodeView_validUntil_f00d {
  display: inline-block;
  font-size: 22px;
  line-height: 22px;
  font-weight: 300;
}

.QrCodeView_qrCodeView_cf0d {
  width: 100%;
  height: 100%;
  min-height: 540px;
  padding-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 25px;
}
