.JollaFormPage_icon_bd26 {
  margin-top: 20px;
  margin-bottom: 64px;
}

.JollaFormPage_p_c116 {
  margin-bottom: 20px;
}

.JollaFormPage_problems_b11e {
  margin-top: 3em;
  margin-bottom: 3em;
}

.JollaFormPage_link_71cb {
  cursor: pointer;
  text-decoration: underline;
}

.JollaFormPage_snnInput_f6a5 input {
  text-transform: uppercase;
}

.JollaFormPage_snnInput_f6a5 input::-webkit-input-placeholder {
  text-transform: none;
}

.JollaFormPage_snnInput_f6a5 input::-moz-placeholder {
  text-transform: none;
}

.JollaFormPage_snnInput_f6a5 input:-ms-input-placeholder {
  text-transform: none;
}

.JollaFormPage_snnInput_f6a5 input::placeholder {
  text-transform: none;
}
