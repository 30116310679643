.Paywall_paywall_435a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center
}
.Paywall_paywall_435a .Paywall_paywallDialog_9d29 {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-height: 60%;
    width: 80%;
    color: black;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3)
  }
.Paywall_paywall_435a .Paywall_paywallDialog_9d29 button {
      padding: 10px;
      margin: 10px 0;
    }
