@media only screen and (min-width: 801px) {
  .LogoutHeader_container_daa8 {
    padding: 0 32px;
    height: 85px;
  }
  .LogoutHeader_iconLogo_75fd {
    height: 29px;
    width: 90px;
  }
  .LogoutHeader_right_4db7 {
    flex: initial;
  }
}

.LogoutHeader_linkWrapper_e972 {
  margin-left: 40px;
}

.LogoutHeader_logoutText_6a10 {
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap
}

.LogoutHeader_logoutText_6a10:hover {
    color: #fd1e6e;
  }

.LogoutHeader_headerItemContainer_82ee {
  display: flex;
  margin-left: 32px
}

.LogoutHeader_headerItemContainer_82ee > p {
    font-size: 13px;
    margin-right: 11px;
    text-transform: uppercase;
  }

.LogoutHeader_iconWrapper_c7d4 {
  cursor: pointer;
  display: flex;
}

.LogoutHeader_middle_c907 {
  justify-content: center;
  flex-basis: auto;
  min-width: 0;
  margin: 0 10px
}

.LogoutHeader_middle_c907 > p {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.LogoutHeader_mobileIcon_64c8 {
  margin-right: 15px;
}


.LogoutHeader_logoutIcon_22b7,
.LogoutHeader_closeIcon_be55 {
  height: 31px;
  width: 31px;
}

.LogoutHeader_linkContent_102c {
  font-size: 14px;

  display: flex;
  flex-direction: row;
  text-transform: uppercase
}

.LogoutHeader_linkContent_102c > *:not(:first-child) {
    margin-left: 20px;
  }

.LogoutHeader_verify_ce5f {
  margin-right: 20px;
}
