.StartTrialPage_collapsibleContainer_f4e0 {
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.StartTrialPage_collapsibleHeader_cd2e,
.StartTrialPage_collapsibleHeaderOpen_1429 {
  padding: 10px;
  width: 100%;
  display: block;
  cursor: pointer;
}

.StartTrialPage_collapsibleHeader_cd2e:after,
.StartTrialPage_collapsibleHeaderOpen_1429:after {
  content: '^';
  display: inline-block;
  position: absolute;
  right: 20px;
  font-size: 20px;
  font-weight: bold;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.StartTrialPage_collapsibleHeaderOpen_1429:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.StartTrialPage_collapsibleContent_d0ab {
  padding: 10px;
}

.StartTrialPage_urlOk_4036 {
  font-size: 13px;
  margin-top: -25px;
}

.StartTrialPage_checkIcon_3345 {
  display: inline-block;
  width: 20px !important; /* To override <SvgIcon> 1em width */
  height: 20px;
  margin-right: 10px;
  vertical-align: middle
}

.StartTrialPage_checkIcon_3345 path {
    fill: currentColor;
  }
