@media only screen and (min-width: 801px) {
  .TopMiddleBottomLayout_stackMiddle_f3ee .TopMiddleBottomLayout_middle_a378 {
    /*flex-grow: initial;*/
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

.TopMiddleBottomLayout_wrapper_6ea0 {
  /* https://stackoverflow.com/a/38295524/7010222 */
  min-height: 100%;
  display: flex;
}

.TopMiddleBottomLayout_container_81a5 {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px;
  box-sizing: border-box
}

.TopMiddleBottomLayout_container_81a5.TopMiddleBottomLayout_fitHeight_5475 {
    min-height: inherit;
    height: inherit;
    max-height: inherit;
  }

.TopMiddleBottomLayout_container_81a5.TopMiddleBottomLayout_noPadding_1921 {
    padding: 0
  }

.TopMiddleBottomLayout_container_81a5.TopMiddleBottomLayout_noPadding_1921 [class*='withGutter'] {
      padding-left: 32px;
      padding-right: 32px;
    }

.TopMiddleBottomLayout_top_8b63 {
  flex: none;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start
}

.TopMiddleBottomLayout_top_8b63.TopMiddleBottomLayout_autoHeight_350c {
    height: auto;
  }

.TopMiddleBottomLayout_top_8b63.TopMiddleBottomLayout_sticky_1bd7 {
    display: block;
    height: auto;
    position: fixed;
    z-index: 4; /* stack on top of transform: translate elements */
    width: 100%;
  }

.TopMiddleBottomLayout_middle_a378 {
  flex: 1;
}

@media only screen and (min-width: 801px) {
  .TopMiddleBottomLayout_middle_a378.TopMiddleBottomLayout_horizontallyCenterMiddle_551e {
    width: calc(100% - 20px);
  }
}

@media only screen and (min-width: 1168px) {
  .TopMiddleBottomLayout_horizontallyCenterMiddle_551e > .TopMiddleBottomLayout_middle_a378 {
    width: 1168px;
  }
}

.TopMiddleBottomLayout_middle_a378.TopMiddleBottomLayout_navbar-push_0f82 {
  margin-bottom: 50px;
}

.TopMiddleBottomLayout_topLeft_c8c6 {
  flex: none;
}

.TopMiddleBottomLayout_topCenter_3895 {
  flex-grow: 1;
}

.TopMiddleBottomLayout_topRight_6b2f {
  flex: none
}

.TopMiddleBottomLayout_topRight_6b2f.TopMiddleBottomLayout_sideBySide_3988 {
    display: flex;
    justify-content: center;
  }

.TopMiddleBottomLayout_bottom_ef11 {
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.TopMiddleBottomLayout_bottomCenter_62f9 {
  flex-grow: 1
}

.TopMiddleBottomLayout_bottomCenter_62f9.TopMiddleBottomLayout_flexRow_15bf {
    display: flex;
    flex-direction: row;
  }

.TopMiddleBottomLayout_scroll_29fe {
  overflow-y: auto;
}
