.Features_featuresWrapper_a12e {
  margin-top: 100px;
  margin-bottom: 100px;
}

.Features_featureHeadlineWrapper_4aac {
  flex: 1;
  max-width: 100%;
  & + & {
    border-left: 1px solid;
    margin-left: -1px;
  }
}

.Features_featureHeadlineWrapper_4aac.Features_active_9e23.Features_red_89c9, .Features_featureHeadlineWrapper_4aac:hover.Features_red_89c9 {
      background-color: #fd1e6e;
    }

.Features_featureHeadlineWrapper_4aac.Features_active_9e23.Features_blue_e3a0, .Features_featureHeadlineWrapper_4aac:hover.Features_blue_e3a0 {
      background-color: #04a3ca;
    }

.Features_featureHeadlineWrapper_4aac.Features_active_9e23,
  .Features_featureHeadlineWrapper_4aac:hover {
    background-color: rgba(18, 24, 33, 0.9) /* #121821 */
  }

.Features_featureHeadlineWrapper_4aac.Features_active_9e23 .Features_featureHeadlineWrapperBackground_f979, .Features_featureHeadlineWrapper_4aac:hover .Features_featureHeadlineWrapperBackground_f979 {
      -webkit-filter: none;
              filter: none;
    }

.Features_featureHeadlineWrapper_4aac.Features_active_9e23 .Features_arrowDown_7cbe, .Features_featureHeadlineWrapper_4aac:hover .Features_arrowDown_7cbe {
      /* https://css-tricks.com/snippets/css/css-triangle/ */
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;

      border-top: 11px solid #fff;
    }

.Features_featureHeadlineWrapper_4aac.Features_active_9e23 .Features_featureHeadlineContent_3b62, .Features_featureHeadlineWrapper_4aac:hover .Features_featureHeadlineContent_3b62 {
      /* raise by the amount of triangle */
      margin-top: -11px;
    }

.Features_featureHeadlineWrapper_4aac {
  /* For the background image */
  position: relative;
  height: 282px;
  background-color: #121821;
}

.Features_featureHeadlineWrapperBackground_f979 {
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.Features_featureHeadlineContainer_49d2 {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}

.Features_featureHeadlineContent_3b62 {
  height: 100%;
}

.Features_featureHeadlineTitle_8eef {
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 0;
}

.Features_featureHeadlinePrice_7be6 {
  font-size: 16px;
  line-height: 26px;
}
