@media only screen and (max-width: 1750px) {
  .index_discountBox_1a00 {
    padding: 10px 26px !important;
  }
}

.index_codesNotFoundBox_afd1 {
  min-height: 62px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background: white;
  color: #121821;
}

.index_offerUrlDesc_8461 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 15px;
}

.index_showCodeBox_5bb4,
.index_spinnerContainer_617f {
  display: flex;
  align-items: center;
  justify-content: center;
}

.index_showCodeBox_5bb4 {
  position: relative;
  height: 62px;
  border: 2px solid #feed01;
  font-size: 20px;
  color: #feed01;
  letter-spacing: 2px;
  cursor: pointer
}

.index_showCodeBox_5bb4 .index_spinnerContainer_617f {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    box-sizing: content-box;
    padding: 2px;
    background: rgba(18, 24, 33, 0.7);
    width: 100%;
    height: 100%
  }

.index_showCodeBox_5bb4 .index_spinnerContainer_617f > .index_spinner_f465 {
      width: 2em;
    }

.index_discountBox_1a00 {
  background: #121821;
  border-radius: 6px;
  padding: 15px 40px;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.index_modalMiddle_58ab {
  display: flex;
  align-items: center
}

.index_modalMiddle_58ab > div {
    width: 100%;
    text-align: center;
    word-break: break-all;
  }

.index_companyBox_0d24 {
  margin-top: 41px;
}

.index_checkIconContainer_45a7 {
  display: flex;
  justify-content: flex-end
}

.index_checkIconContainer_45a7 > div {
    z-index: 3;
    width: 30px;
    height: 30px;
    display: inherit;
    align-items: center;
    justify-content: center;
    background: #fd1e6e;
    margin: 0 10px;
    border-radius: 30px;
    box-shadow: 0 0 0 5px rgba(253, 30, 110, 0.5);
  }

.index_copyIcon_cb96 {
  background: #feed01;
  width: 2em !important;
  cursor: pointer;
}

.index_copyIconContainer_8e03 {
  display: flex;
  justify-content: flex-end;
  max-width: 20px;
  margin-left: 10px
}

.index_copyIconContainer_8e03 path {
    fill: currentColor;
  }

.index_discountCodeContainer_31d7 > p {
    margin-bottom: 15px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: #717d8d;
  }

.index_discountCodeText_16d3,
.index_voucherRedeemText_c249 {
  font-size: 16px;
  word-wrap: break-word;
  text-align: center;
  margin: 7px 0;
  max-width: 250px;
}

.index_discountCode_0d9f,
.index_copiedOverlay_92b8,
.index_voucherRedeem_c58f {
  justify-content: center;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.index_discountCode_0d9f {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  min-height: 62px;
  width: 100%;
  background-color: #feed01;
  color: black;
  padding: 10px
}

.index_discountCode_0d9f > span {
    z-index: 2;
  }

.index_voucherRedeem_c58f {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  min-height: 62px;
  width: 100%;
  color: #feed01;
  border: 2px solid #feed01
}

.index_voucherRedeem_c58f > span {
    z-index: 2;
  }

.index_addressText_2d13 {
  white-space: pre-wrap;
}

.index_checkIcon_1603 {
  padding: 0 5px;
  width: 1em !important;
}

.index_advertiserInfoContainer_6397 {
  display: flex;
  min-height: 50px;
  flex-direction: column;
  justify-content: space-between
}

.index_advertiserInfoContainer_6397 > a {
    text-decoration: underline;
  }

.index_innerHtmlContainer_66f6 > p {
    font-size: 14px;
  }

.index_openHoursInfoContainer_1359 > div > p {
    font-size: 14px;
  }

.index_discountBoxItem_5b24 {
  margin: 18px 0
}

.index_discountBoxItem_5b24.index_aboutHeader_7c19 {
    line-height: normal;
    margin-bottom: 10px !important;
    font-size: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
  }
