.index_h1_0a5d {
  margin-top: 43px;
  margin-bottom: 35px;
}

.index_pwResetToastText_deb3 {
  display: flex;
  min-width: 12em;
  justify-content: space-between;
  align-items: center
}

.index_pwResetToastText_deb3 > p {
    font-size: 16px;
  }

.index_messageBubble_39d2 {
  width: 2em !important;
}

.index_selectorContainer_f502 {
  display: flex;
  align-items: center;
  min-width: 80px;
  font-size: 13px;
  margin-left: 20px;
}

.index_onboardingHeader_3956 {
  height: 40px;
}
