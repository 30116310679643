@-webkit-keyframes index_placeHolderShimmer_b6be {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes index_placeHolderShimmer_b6be {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.index_placeHolderBar_0fdf {
  height: 20px;
  border-radius: 10px;
  margin: 5px;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-name: index_placeHolderShimmer_b6be;
          animation-name: index_placeHolderShimmer_b6be;
  background: linear-gradient(to right, #ffffff 8%, #e2e2e2 18%, #ffffff 33%);
  background-size: 1024px 104px
}

.index_placeHolderBar_0fdf.index_top_b856 {
    width: 70%;
  }

.index_placeHolderBar_0fdf.index_bottom_1211 {
    width: 50%;
  }

.index_animatedBarContainer_147e {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column
}

.index_animatedBarContainer_147e.index_red_dc74 {
    background-color: rgba(253, 30, 110, 0.4);
  }

.index_animatedBarContainer_147e.index_blue_023e {
    background-color: #98d5e5;
  }
