.SelectBoxField_container_aa41 {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: none;
  flex-grow: 1;
  justify-content: flex-start;
  margin-bottom: 28px
}

.SelectBoxField_container_aa41.SelectBoxField_normal_bed1:before {
    display: block;
    content: ' ';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 1px;
    background: #a9a9a9;
  }

.SelectBoxField_container_aa41.SelectBoxField_normal_bed1:after {
    display: block;
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background: #fd1e6e;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    will-change: transform;
    transition: -webkit-transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
    transition: transform 0.3s cubic-bezier(0, 1.01, 0.58, 1), -webkit-transform 0.3s cubic-bezier(0, 1.01, 0.58, 1);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }

.SelectBoxField_container_aa41.SelectBoxField_inFocus_f303:after {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

.SelectBoxField_topRow_2759 {
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: flex-start;
}

.SelectBoxField_normal_bed1 .SelectBoxField_label_0c9c {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #a9a9a9;
  margin-bottom: 13px;
  flex-grow: 1;
}

.SelectBoxField_light_c50e .SelectBoxField_label_0c9c {
  display: none;
}
