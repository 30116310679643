.ErrorBoundary_container_2acf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.ErrorBoundary_container_2acf > .ErrorBoundary_headerAndButtonBox_0654 {
  text-align: center;
}

.ErrorBoundary_container_2acf > .ErrorBoundary_messageBox_0f4c {
  font-size: smaller;
  padding: 36px;
}

.ErrorBoundary_container_2acf > .ErrorBoundary_messageBox_0f4c > .ErrorBoundary_listPaddingFix_6054 {
  padding-left: 40px;
}
