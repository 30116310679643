@media only screen and (min-width: 801px) {
  .NewsTeaser_modalContent_713b {
    top: 85px !important;
  }
}

.NewsTeaser_container_4a42 {
  display: flex;
  padding: 11px 17px;
  min-height: 40px;
  background: white;
}

.NewsTeaser_modalContent_713b {
  top: 66px;
  z-index: 2;
  position: fixed;
  width: 100vw;
  min-height: 360px;
}

/* TODO: Quickfix for iOS: Modal content is placed relative to phone screen,
         not the top of what the browser is rendering */
.NewsTeaser_modalContentIos_228a {
  top: 100px;
}

.NewsTeaser_modal_4aa1 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: #121821;
  opacity: 0.9;
}

.NewsTeaser_teaserTextContainer_f2b9 {
  flex: 70%
}

.NewsTeaser_teaserTextContainer_f2b9 > p {
    font-weight: 500;
    max-height: 100%;
    max-width: 100%;
    line-height: 19px;
  }

.NewsTeaser_teaserIconContainer_8a3d,
.NewsTeaser_teaserTextContainer_f2b9 {
  display: inherit;
  align-items: center;
}

.NewsTeaser_teaserIconContainer_8a3d {
  justify-content: flex-end;
  flex: 30%;
}

.NewsTeaser_teaserIcon_f0fd {
  width: 31px !important
}

.NewsTeaser_teaserIcon_f0fd g {
    fill: black;
  }
