.index_h1_c7db {
  margin-top: 43px;
  margin-bottom: 35px;
}

.index_paragraph_a2da {
  font-size: 13px;
  margin-bottom: 41px;
}

.index_selectorContainer_7013 {
  display: flex;
  align-items: center;
  min-width: 80px;
  font-size: 13px;
  padding-left: 20px;
}

.index_onboardingHeader_8bbb {
  height: 40px;
}
