.VerifyEmailPage_editEmailContainer_d57f {
  padding: 120px 25px 60px 25px;
}

.VerifyEmailPage_editEmailContainer_d57f h1 {
  line-height: 1.1;
}

.VerifyEmailPage_editEmailForm_9d4f {
  margin: 35px 0 15px 0;
}
