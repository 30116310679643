.index_container_f29d {
  display: flex;
  flex-direction: column;
  justify-content: center
}

.index_container_f29d .index_label_6077 {
    display: flex;
    color: #121821;
    font-size: 16px;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased
  }

.index_container_f29d .index_label_6077 .index_arrow_54db {
      display: flex;
      margin-left: 4px
    }

.index_container_f29d .index_label_6077 .index_arrow_54db > svg {
        width: 15px;
        height: 10px;
      }

.index_container_f29d .index_dropDown_8279 {
    color: black;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5px
  }

.index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 {
      color: #828282;
      display: flex;
      align-items: center;
      height: 50px
    }

.index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 .index_itemIcon_02fe path {
          fill: #fd1e6e;
        }

.index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 .index_itemText_8e12 {
        margin-left: 5px;
        line-height: 100%;
      }

.index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 .index_selectedItemText_e116 {
        color: #fd1e6e;
      }

.index_container_f29d .index_dropDown_8279 .index_collapse_98ce {
      background-color: #ffffff;
      font-size: 13px;
      text-align: right;
      cursor: pointer;
      padding: 0 0 10px 10px;
      display: flex;
      flex-basis: 100% !important;
    }

@media only screen and (min-width: 801px) {
  .index_container_f29d {
    margin-left: 15px;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
      .index_container_f29d .index_label_6077 .index_arrow_54db {
        margin-top: 7px;
        margin-left: 7px;
      }

    .index_container_f29d .index_dropDown_8279 {
      position: absolute;
      margin: 0 !important;
      top: 125px;
      background-color: #ffffff;
      font-size: 14px;
      border-radius: 6px;
      box-shadow: 0 1px 21px 0 rgba(0, 0, 0, 0.13);
      max-width: 500px
    }

      .index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 {
        width: 140px;
        margin: 10px 10px 5px 10px;
        height: 40px;
      }

      .index_container_f29d .index_dropDown_8279 .index_itemIcon_02fe {
        width: calc(100% / 12 * 2.5)
      }

        .index_container_f29d .index_dropDown_8279 .index_itemIcon_02fe > svg {
          width: 100%;
        }

      .index_container_f29d .index_dropDown_8279 .index_itemText_8e12 {
        width: calc(100% / 12 * 9.5);
      }

  .index_selectedContainer_c487 {
    background-color: #f6f6f6;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 800px) {
  .index_container_f29d {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    background-color: #ffffff
  }

    .index_container_f29d .index_label_6077 {
      height: 40px;
      margin-left: 15px;
      align-items: center
    }

      .index_container_f29d .index_label_6077 .index_arrow_54db {
        margin-bottom: 5px;
      }

    .index_container_f29d .index_dropDown_8279 {
      background-color: #f2f2f2;
      font-size: 13px;
      justify-content: space-around
    }

      .index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 {
        align-items: center;
        width: 130px;
        height: 50px;
        margin: 0px 3px 0px 3px
      }
        .index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 .index_itemIcon_02fe {
          width: calc(100% / 12 * 3)
        }

          .index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 .index_itemIcon_02fe > svg {
            width: 100%;
          }

        .index_container_f29d .index_dropDown_8279 > .index_dropDownItem_5b25 .index_itemText_8e12 {
          width: calc(100% / 12 * 9);
        }
      .index_container_f29d .index_dropDown_8279 .index_collapse_98ce {
        background-color: #f2f2f2;
        padding-left: 30px;
      }
}
