.LoadingItem_container_3f1f {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a3038;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 100%;
  height: 100%
}
.LoadingItem_container_3f1f.LoadingItem_cell_2e0e {
    border-radius: 8px;
    border-bottom-color: transparent;
  }
