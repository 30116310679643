.EndingPage_marginTop1em_11e2 {
  margin-top: 1em;
}

.EndingPage_marginTop2em_fd61 {
  margin-top: 2em;
}

.EndingPage_marginBottom1em_82d3 {
  margin-bottom: 1em;
}

.EndingPage_marginBottom2em_441a {
  margin-bottom: 2em;
}
