.index_left_4209 {
  background: linear-gradient(rgba(254, 237, 1, 0.65), rgba(254, 237, 1, 0.65)),
    url(/68fd1ba1f022d6e5ab77ba921df0ebf3.jpg);
  background-size: cover;
  background-position: 25% 75%;
}

.index_logo_57aa {
  margin-left: 69px;
  margin-top: 56px;
}
