.SvgIcon_container_e8a5 {
}

.SvgIcon_container_e8a5.SvgIcon_em_d8af {
  width: 1em;
}

.SvgIcon_container_e8a5.SvgIcon_fill_5fe2 {
  width: 100%;
}
