.ContinueButton_container_e858 {
  width: 60px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  /*fill: black;*/
  color: black;
  font-size: 24px;
  border-radius: 50%;
}

.ContinueButton_disabled_bddf {
  background: grey;
  fill: #222;
}
