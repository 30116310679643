.CheckBoxField_container_7ad2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.CheckBoxField_left_b625 {
  flex: none;
  margin-right: 15px;
  width: 33px;
  height: 31px;
}

.CheckBoxField_right_065d {
  flex-grow: 1;
}

.CheckBoxField_icon_475a {
  width: 100%;
  fill: #ffffff;
}

.CheckBoxField_label_6f55 {
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
}
