@media only screen and (max-width: 800px) {
  .SearchIcon_container_efeb {
    font-size: 1em; /* This controls the size. */
    display: inline-block;
    width: 0.48em;
    height: 0.48em;
    border: 0.1em solid white;
    position: relative;
    border-radius: 0.35em;
  }
  .SearchIcon_container_efeb::before {
    content: '';
    display: inline-block;
    position: absolute;
    right: -0.25em;
    bottom: -0.1em;
    border-width: 0;
    background: white;
    width: 0.35em;
    height: 0.08em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }

  .SearchIcon_active_5f8d {
    border-color: #fd1e6e !important;
  }
  .SearchIcon_active_5f8d::before {
    background: #fd1e6e !important;
  }

}

@media only screen and (min-width: 801px) {
  .SearchIcon_container_efeb {
    width: 100%;
    font-size: 1.2em; /* This controls the size. */
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border: 0.1em solid rgba(15, 21, 31, 0.49);
    position: relative;
    border-radius: 0.35em;
  }
  .SearchIcon_container_efeb::before {
    content: '';
    display: inline-block;
    position: absolute;
    right: -0.3em;
    bottom: -0.2em;
    border-width: 0;
    background: rgba(15, 21, 31, 0.49);
    width: 0.40em;
    height: 0.11em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
}