.index_spinnerContainer_fa03 {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  overflow: hidden
}
.index_spinnerContainer_fa03 > div {
    width: 3em !important;
  }
.index_spinnerContainer_fa03 > p {
    margin-top: 30px;
  }
