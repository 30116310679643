.index_container_9fc1 {
  display: flex;
  height: 40vh;
}

.index_body_9085 {
  text-align: center;
  margin: auto;
  color: #606060;
  font-size: 24px;
}
