.index_payment_c580 {
  margin-top: 28px;
  margin-bottom: 28px;
}

@media only screen and (max-width: 800px) {
  .index_content_7e73.index_stretch_7bb3 {
    width: 100%;
  }
}

@media only screen and (min-width: 801px) {
  .index_content_7e73.index_stretch_7bb3 {
    width: 100%;
  }
}
