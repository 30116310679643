.H1_container_9aee {
  font-family: 'Nexa Bold', sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 18px;
}

.H1_normal_ad4d {
  color: white;
}

.H1_white_1a01 {
  color: white;
}

.H1_black_d57c {
  color: #121821;
}
