@media only screen and (min-width: 801px) {
  .index_container_3ba0 {
    height: 65px;
  }
}

@media only screen and (max-width: 800px) {
  .index_container_3ba0 {
    height: 40px;
  }
}

.index_container_3ba0 {
  background-color: #121821;

  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.index_left_c1d4 {
  flex-grow: none;
  flex-shrink: none;
  width: 67px;
}

.index_middle_cf7e {
  flex-grow: 1;
}

.index_right_f433 {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
}

.index_icon_8f74 {
  margin-left: 40px;
  font-size: 0.8em;
}

.index_profile_ed99 {
  font-size: 1em;
}

.index_loginLink_827c {
  font-size: 13px;
  color: #fd1e6e;
  font-weight: 500;
}
