.StudentCard_paywallBlur_a3ed {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.StudentCard_white_7c4f {
  background-color: white;
  color: black;
}

.StudentCard_blue_f264 {
  background-color: #498edf;
  color: white;
}

.StudentCard_green_7506 {
  background-color: #37bb9d;
  color: white;
}
