.ChangeGraduationYear_editPhoneNumberForm_ff8f {
  transition: all 0.3s ease-out;
  margin: 35px 0 15px 0;
}

.ChangeGraduationYear_editPhoneNumberContainer_8118 {
  padding: 120px 25px 60px 25px;
}

.ChangeGraduationYear_editPhoneNumberContainer_8118 h1 {
  line-height: 1.1;
}

.ChangeGraduationYear_editPhoneNumberContainer_8118 h3 {
  font-size: 16px;
  color: rgb(169, 169, 169);
  margin: 35px 0 15px 0;
}

.ChangeGraduationYear_button_abef {
  padding: 10px 34px 11px 34px !important;
}

.ChangeGraduationYear_did-not-get-verification-message_94d3 {
  font-size: 10pt;
  margin-bottom: 15px;
}

.ChangeGraduationYear_disclaimerElement_f957 {
  margin-top: 15px;
  margin-bottom: 20px;
}
