@media only screen and (min-width: 801px) {
  .ConversationLayout_wrapper_bba1 {
    height: 100%;
    display: flex;
  }

  .ConversationLayout_container_c844 {
    padding-bottom: 100px;
  }

  .ConversationLayout_dialogContainer_2e21 {
    max-width: 559px;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.32);
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ConversationLayout_buttonsContainer_00e6 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .ConversationLayout_footer_1fdb {
    background-color: #dddddd;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0
  }
    .ConversationLayout_footer_1fdb .ConversationLayout_logoContainer_4e3f {
      width: 93px;
      padding-right: 32px;
    }
}

@media only screen and (max-width: 800px) {
  .ConversationLayout_dialogContainer_2e21 {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    min-height: 100vh;
  }
  .ConversationLayout_contentWrapper_4426 {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  }
  .ConversationLayout_main_cfbf.ConversationLayout_stretch_342d {
    align-items: initial;
    width: 100%;
  }
  .ConversationLayout_buttonsContainer_00e6 {
    width: 100%;
  }
  .ConversationLayout_footer_1fdb {
    display: none;
  }
}

.ConversationLayout_wrapper_bba1 {
  background: #f2f2f2;
}

.ConversationLayout_contentWrapper_4426.ConversationLayout_cordova_85b2 {
  margin-bottom: 50px; /* Space for navigation bar */
}

.ConversationLayout_container_c844 {
  background-color: #f2f2f2;
  width: 100%;
  margin: auto;
}

.ConversationLayout_dialogContainer_2e21 {
  background: linear-gradient(#db0c52, #f15b2a)
}

.ConversationLayout_dialogContainer_2e21.ConversationLayout_blue_09c4 {
    background: linear-gradient(#04a3ca, #036f89);
  }

.ConversationLayout_dialogContainer_2e21.ConversationLayout_black_dcb4 {
    background: linear-gradient(#000000, #5e5e5e);
  }

.ConversationLayout_item_fd13 {
  padding: 1em;
}

.ConversationLayout_main_cfbf {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  padding-top: 0;
}

.ConversationLayout_header_52cf {
  align-items: center;
  display: flex;
  flex-direction: row
}

.ConversationLayout_header_52cf .ConversationLayout_stretch_342d {
    flex-grow: 1;
  }

.ConversationLayout_navigationIcon_87ff {
  cursor: pointer;
}

.ConversationLayout_back_8364 {
  width: 32px;
  height: 25px;
}

.ConversationLayout_close_3500 {
  width: 25px;
  height: 25px;
}

.ConversationLayout_buttonsContainer_00e6 {
  background-color: rgba(255, 255, 255, 0.1);
}
