.ProfilePhotoRequiredPromptModal_icon_92e0 {
  width: 163px;
  height: 51px;
  padding-top: 88px;
  padding-right: 20px;
  padding-bottom: 88px;
}

.ProfilePhotoRequiredPromptModal_message_d55c {
  margin-top: 2em;
}

.ProfilePhotoRequiredPromptModal_button_7cad {
  margin-top: 90px;
}
