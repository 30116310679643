.BurgerMenu_menuWrapper_4b94 {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 65px; /* The height of the header */
  left: 0;
  height: calc(100vh - 65px); /* 65px is the height of the header */
  width: 100vw;
  overflow-y: scroll;
  flex-direction: column;
  text-align: center;
  background-color: #f2f2f2;
  padding: 40px;
  color: #121821
}

.BurgerMenu_menuWrapper_4b94 > * {
    flex-grow: 1;
  }

.BurgerMenu_menuWrapper_4b94.BurgerMenu_cordova_69d7 {
  z-index: 10;
  height: 100vh; /* 65px is the height of the header */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 80px 40px 90px 40px
}

.BurgerMenu_menuWrapper_4b94.BurgerMenu_cordova_69d7.BurgerMenu_studentcard_be78 {
    height: 100%;
  }

.BurgerMenu_header_c5bf {
  padding: 0 20px;
  border-bottom: 1px solid #363f4c;
  height: 65px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #121821;
  display: flex;
  flex: 1;
}

.BurgerMenu_iconLogo_8fb4 {
  height: 22px;
  width: 67px;
  margin: auto;
}

.BurgerMenu_welcomePhrase_3d33 {
  font-size: 18px;
  font-weight: 500;
}

.BurgerMenu_buttonsContainer_62db {
  padding: 20px 0
}

.BurgerMenu_buttonsContainer_62db .BurgerMenu_menuButton_bb66 {
    width: 100%;
    border: 2px solid #fd1e6e;
    color: #fd1e6e;
    padding: 12px;
    margin-top: 14px
  }

.BurgerMenu_buttonsContainer_62db .BurgerMenu_menuButton_bb66.BurgerMenu_disabled_ded7 {
      border: 2px solid grey;
      color: grey;
      cursor: not-allowed;
    }

.BurgerMenu_betaFlag_c732 {
  font-size: 8pt;
  position: relative;
  top: -6px;
}

.BurgerMenu_linksContainer_01c6 {
  padding: 20px 0
}

.BurgerMenu_linksContainer_01c6 > * {
    display: block;
    text-decoration: underline;
    font-size: 16px;
    line-height: 30px;
  }

.BurgerMenu_socialIconsContainer_60d8 {
  display: flex;
  justify-content: center;
  padding: 20px 0
}

.BurgerMenu_socialIconsContainer_60d8 > *:not(:last-child) {
    margin-right: 10px;
  }

.BurgerMenu_socialIcon_a1a8 {
  height: 38px;
  width: 38px;
}

.BurgerMenu_notLogged_5f34 .BurgerMenu_closeIcon_e7a1 {
    top: 80px;
  }

.BurgerMenu_rightContent_8b44 {
  display: flex;
  position: absolute;
  right: 20px;
}

.BurgerMenu_languageSelector_44c6 {
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-left: 30px;
  flex: initial;
  align-self: center;
  font-size: 16px;
  font-weight: 400;
  width: 45px
}

.BurgerMenu_languageSelector_44c6::before {
    display: none !important;
  }

.BurgerMenu_languageSelector_44c6 .Select-value {
    padding-right: 0px !important;
  }

.BurgerMenu_languageSelector_44c6 .Select-value-label {
    float: left;
  }

.BurgerMenu_languageSelector_44c6 .Select-arrow-zone {
    width: auto;
    width: initial;
    padding-right: 0px !important;
  }

.BurgerMenu_languageSelector_44c6 .Select-arrow {
    border-color: #fff transparent transparent;
  }

.BurgerMenu_version_0f51 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
}
