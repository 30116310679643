.Form_card_b804 {
  background: white;
  padding-left: 15px;
  border: 1px solid white;
  border-radius: 5px;
}

.Form_acceptedCardsWrapper_2f4b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin: 14px 0 10px;
}

.Form_cardThumbnail_b40b {
  max-height: 30px;
  margin-left: 10px;
}
