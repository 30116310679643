.ErrorCandy_container_c881 {
  z-index: 25;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fd1e6e;
  transition: margin 0.2s ease-out, opacity 0.2s ease-out;
  opacity: 0;
  margin-left: 100%
}

.ErrorCandy_container_c881.ErrorCandy_show_f53f {
    opacity: 1;
    margin-left: 0;
  }

.ErrorCandy_container_c881.ErrorCandy_centerContent_24a0 .ErrorCandy_content_7c75 {
    padding: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.ErrorCandy_container_c881.ErrorCandy_centerContent_24a0 .ErrorCandy_closeButtonContainer_73ab {
    top: 30px;
  }

.ErrorCandy_container_c881.ErrorCandy_push_c1a5 {
    margin-bottom: 50px;
  }

.ErrorCandy_white_5b00 {
  background: white;
  color: #fd1e6e
}

.ErrorCandy_white_5b00 g {
    stroke: #fd1e6e;
  }

.ErrorCandy_top_18f6 {
  bottom: auto;
  top: 0;
}

.ErrorCandy_closeButtonContainer_73ab {
  position: absolute;
  top: 22px;
  right: 32px;
}

.ErrorCandy_content_7c75 {
  padding: 16px 75px 26px 32px;
  font-size: 14px;
}
