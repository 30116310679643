@media only screen and (max-width: 800px) {
  .HeroLayout_middle_a6eb {
    /* @note match with top height */
    margin-top: 65px;
  }
  .HeroLayout_body_39c9 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .HeroLayout_layoutContainer_0820 {
    flex-direction: column;
    padding: 55px 25px 25px 25px;
  }

  .HeroLayout_descriptionContainer_2584 {
    flex: 1;
  }

  .HeroLayout_boxContainer_89e6 {
    flex: 1;
  }
}

.HeroLayout_heroPlaceholder_8e91 {
  background-color: transparent;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.HeroLayout_heroPlaceholderImage_0337 {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 801px) {
  .HeroLayout_middle_a6eb {
    /* @note match with top height */
    margin-top: 85px;
  }
  .HeroLayout_image_7522 {
    max-width: 55vw;
  }

  .HeroLayout_descriptionWrapper_efb3 {
    margin-right: 2em;
  }

  .HeroLayout_layoutContainer_0820 {
    flex-direction: row;
    padding: 55px 0px 0px 0px;
  }

  .HeroLayout_descriptionContainer_2584 {
    flex: 2;
    padding-right: 5px;
  }

  .HeroLayout_boxContainer_89e6 {
    flex: 1;
    padding-left: 5px;
  }
}

.HeroLayout_middle_a6eb {
  background: #fff;
  padding-bottom: 50px;
}

.HeroLayout_hero_83ec {
  display: flex;
  justify-content: center;
  background-color: #f2f2f2
}

.HeroLayout_hero_83ec img {
    width: 100%;
  }

.HeroLayout_columnLarge7_69a6 {
  position: relative;
}

.HeroLayout_image_7522 {
  width: 100%;
}

.HeroLayout_body_39c9 {
  position: relative;
}

.HeroLayout_iconContainer_61ea {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.HeroLayout_back_bf37 {
  z-index: 1;
  background: rgba(254, 251, 252, 0.95);
  border-radius: 50%;
  border-style: solid;
  border-color: #fd1e6e;
  border-width: 2px;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: -25px; /* size / 2 */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.HeroLayout_share_c47e {
  z-index: 1;
  background: rgba(254, 251, 252, 0.95);
  border-radius: 50%;
  border-style: solid;
  border-color: #fd1e6e;
  border-width: 2px;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: -25px; /* size / 2 */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.HeroLayout_star_bb36 {
  z-index: 1;
  background: rgba(254, 251, 252, 0.95);
  border-radius: 50%;
  border-style: solid;
  border-color: #fd1e6e;
  border-width: 2px;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: -25px; /* size / 2 */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.HeroLayout_visitAdvertiser_b635 {
  z-index: 1;
  background: rgba(254, 251, 252, 0.95);
  border-radius: 25px;
  border-style: solid;
  border-color: #fd1e6e;
  padding: 10px;
  border-width: 2px;
  height: 50px;
  margin-left: 10px;
  margin-top: -25px; /* size / 2 */
  display: flex;
  color: fd1e6e;
  justify-content: center;
  align-items: center;
}

.HeroLayout_offerUrlDesc_b876 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 15px;
  font-weight: 400;
  font-family: 'Nexa Bold', sans-serif;
}

.HeroLayout_externalLinkIcon_7ac0 {
  color: #fd1e6e;
  margin-left: 10px;
}

.HeroLayout_iconContainer_61ea a:active {
   background-color:rgba(253, 30, 110, 0.2); 
}

.HeroLayout_extraIcon_b70b {
  z-index: 1;
  background: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-top: -25px; /* size / 2 */
  color: #fd1e6e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.HeroLayout_layoutContainer_0820 {
  background-color: #fff;
  display: flex;
}

.HeroLayout_description_1bc8 {
  margin-bottom: 1em;
  /* fallback font */
  color: #121821;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 22px
  /* fallback font */
}

.HeroLayout_description_1bc8 p {
    color: #121821;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 1em;
  }

.HeroLayout_description_1bc8 li {
    list-style-position: inside;
  }

.HeroLayout_description_1bc8 hr {
    border-bottom: 1px solid #efefef;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

.HeroLayout_description_1bc8 h6 {
    color: #606060;
    font-size: 12px;
    line-height: 1.1;
    font-weight: 500;
  }

.HeroLayout_description_1bc8 a {
    color: #fd1e6e;
  }

.HeroLayout_title_cafd,
.HeroLayout_shortDescription_073e {
  text-overflow: ellipsis;
  overflow: hidden;
}

.HeroLayout_title_cafd {
  line-height: normal !important;
  color: #121821;
  font-size: 32px;
  line-height: 28px;
  margin-bottom: 32px;
  font-weight: 500;
}

.HeroLayout_shortDescription_073e {
  color: #121821;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 18px;
  font-weight: 500;
}

.HeroLayout_tooltip_31f1 {
  z-index: 1;
  position: relative;
  display: inline-block;
}

.HeroLayout_tooltip_31f1 .HeroLayout_tooltiptext_9ae6 {
  width: 100px;
  visibility: hidden;
  background-color: rgb(235, 235, 235);
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  top: 100%;
  left: 50%;
  margin-left: -45px;
  font-size: 14px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.HeroLayout_tooltip_31f1 .HeroLayout_tooltiptext_9ae6::after {
  content: " ";
  position: absolute;
  bottom: 98%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgb(235, 235, 235) transparent;
}
