.SingleArticle_middle_f44d {
  background-color: #f2f2f2;
  padding-bottom: 100px;
}

.SingleArticle_hero_d154 {
  display: flex;
  position: relative;
  margin-top: 85px;
  margin-bottom: 60px;
  justify-content: center
}

.SingleArticle_hero_d154 > .SingleArticle_imageContainer_d195 {
    display: flex;
    width: 100%;
    height: 38vw;
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;
  }

.SingleArticle_textContainer_b2a6 {
  padding: 0 12%;
  width: 100%;
  box-sizing: border-box;
  display: flex
}

.SingleArticle_textContainer_b2a6 > div {
    align-self: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 35px;
  }

.SingleArticle_backButton_bebc {
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  left: 12%;
  bottom: 0;
  background-color: #fd1e6e;
  padding: 15px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  -webkit-transform: translate(0, 50%);
          transform: translate(0, 50%)
}

.SingleArticle_backButton_bebc > svg {
    height: 20px;
    width: 20px;
  }

@media only screen and (max-width: 800px) {
  .SingleArticle_title_6b36 {
    font-size: 30px;
    line-height: 37px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
    margin-bottom: 5px;
  }

  .SingleArticle_teaser_d4eb {
    font-size: 18px;
    line-height: 24px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .SingleArticle_hero_d154 {
    margin-top: 65px
  }

    .SingleArticle_hero_d154 > .SingleArticle_imageContainer_d195 {
      height: 40vh;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 801px) {
  .SingleArticle_title_6b36 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 15px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .SingleArticle_teaser_d4eb {
    font-size: 20px;
    line-height: 32px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }
}

@media only screen and (min-width: 1400px) {
  .SingleArticle_title_6b36 {
    font-size: 55px;
    line-height: 65px;
    margin-bottom: 15px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .SingleArticle_teaser_d4eb {
    font-size: 26px;
    line-height: 32px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }
}
