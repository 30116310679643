.NavBar_container_36a4 {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  position: fixed;
  height: 50px;
  width: 100%;
  bottom: 0;
  background-color: white;
  justify-content: space-evenly;
  z-index: 20;
  color: #121821;
  padding-top: 14px
}

.NavBar_container_36a4 > div {
    width: 30%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

.NavBar_navBarBorder_33ab {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.NavBar_gray_652e {
  background-color: lightgrey;
}

.NavBar_iconContainer_1ef6 {
  width: 100%;
  max-width: 60px;
  display: flex;
  justify-content: center
}

.NavBar_iconContainer_1ef6 path,
  .NavBar_iconContainer_1ef6 polygon,
  .NavBar_iconContainer_1ef6 rect {
    fill: currentColor;
  }

.NavBar_textContainer_354c {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

.NavBar_safeAreaBottom_cecd {
  height: calc(45px + constant(safe-area-inset-bottom)); /* iOS 11.0 */
  height: calc(45px + env(safe-area-inset-bottom)); /* iOS 11.2 */
  padding-bottom: 0;
}

.NavBar_icon_aeb6 {
  width: 60%;
}

.NavBar_iconDefaultColor_15ce {
  color: black; 
  stroke: black; 
}

.NavBar_selected_3f44 {
  color: #fd1e6e !important;
  stroke: #fd1e6e !important; 
}

.NavBar_menuContainer_d437 {
  padding-top: 2px
}

.NavBar_menuContainer_d437 svg {
    max-height: 18px;
  }
