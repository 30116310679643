@media only screen and (min-width: 801px) {
  .align_largeCenterText_9bdd {
    text-align: center;
  }
}

.align_centerText_7c5e {
  text-align: center;
}

.align_center_07f9 {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.align_verticalCenter_706a {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.align_right_0bf0 {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

@media only screen and (min-width: 801px) {
  .align_offsetLarge1_f0ee {
    margin-left: 8.333333%;
  }
  .align_offsetLarge2_4b58 {
    margin-left: 16.666667%;
  }
}
