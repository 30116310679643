.OfflineModal_container_2b8f {
  /* Height is 100vh - top bar - bottom navigation - frank bottom banner.
    Styles have to work only in the apps, as the offline modal is never shown
    in web version.
  */
  height: calc(100vh - 225px);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  text-align: center;
  flex: 1;
}
