@media only screen and (min-width: 1025px) {
    .NewsCard_mobile_57e5.NewsCard_container_010d,
    .NewsCard_mobile_57e5.NewsCard_placeholder_9ee2 {
      width: 70% !important;
    }
}

@media only screen and (min-width: 801px) {
    .NewsCard_mobile_57e5.NewsCard_container_010d,
    .NewsCard_mobile_57e5.NewsCard_placeholder_9ee2 {
      width: 70% !important;
    }
}

@media only screen and (max-width: 800px) {
    .NewsCard_mobile_57e5.NewsCard_container_010d {
      padding: 27px 28px 53px 28px;
    }

    .NewsCard_mobile_57e5 div.NewsCard_slideChangerContainer_9b09 {
      bottom: 17px;
      right: 30px;
      left: 30px;
    }
}

.NewsCard_container_010d {
  position: relative;
  text-align: center;
  padding: 27px 40px 60px 40px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07);
}

.NewsCard_animatedBarContainer_5f6c {
  height: 100%;
}

.NewsCard_slideChangerContainer_9b09 {
  position: absolute;
  bottom: 27px;
  right: 40px;
  left: 40px;
}

.NewsCard_placeholder_9ee2,
.NewsCard_container_010d {
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.NewsCard_headingContainer_ae2e {
  margin-bottom: 18px
}

.NewsCard_headingContainer_ae2e h1 {
    margin: 0;
  }

.NewsCard_mobile_57e5 {
  text-align: left
}

.NewsCard_mobile_57e5.NewsCard_placeholder_9ee2,
  .NewsCard_mobile_57e5.NewsCard_container_010d {
    min-height: 360px;
    max-height: 70vh;
    width: 90%;
  }

.NewsCard_mobile_57e5.NewsCard_placeholder_9ee2 {
    justify-content: center !important;
  }

.NewsCard_mobile_57e5 .NewsCard_timestamp_de0a {
    margin-bottom: 15px;
    font-size: 10px !important;
    line-height: 12px !important;
  }

.NewsCard_mobile_57e5 .NewsCard_heading_f63e {
    display: table-cell;
    vertical-align: top;
    max-width: 80%;
    font-size: 16px;
    line-height: 19px;
    color: #121821;
    font-weight: 500;
  }

.NewsCard_mobile_57e5 .NewsCard_headingContainer_ae2e {
    width: 100%;
    display: table;
  }

.NewsCard_closeIcon_e09a {
  display: table-cell;
  width: 22px !important;
  margin-left: 10px
}

.NewsCard_closeIcon_e09a g {
    stroke: #121821;
  }

.NewsCard_placeholder_9ee2 {
  justify-content: flex-end;
  background-color: #eaeaea;
}

.NewsCard_heading_f63e {
  color: #04a3ca;
}

.NewsCard_timestamp_de0a {
  color: #b0b0b0;
  font-size: 10px;
  text-transform: uppercase;
}

.NewsCard_withUnderline_6a4a {
  margin: 0 auto 18px auto;
  padding-bottom: 18px;
  width: 70px;
  border-bottom: 1px solid #dadada;
}

.NewsCard_content_d865 {
  text-align: left;
  overflow-y: scroll;
  color: #4a4a4a !important;
  font-size: 14px
}

.NewsCard_content_d865 a {
    color: #04a3ca;
  }

.NewsCard_content_d865 ol,
  .NewsCard_content_d865 ul {
    padding-left: 17px;
  }

.NewsCard_content_d865 > * {
    margin: 7px 0;
  }

.NewsCard_nextLink_716d {
  float: right;
}

.NewsCard_slideChanger_f736 {
  cursor: pointer;
  color: #b0b0b0
}

.NewsCard_slideChanger_f736.NewsCard_active_c716 {
    color: #fd1e6e;
  }

.NewsCard_prevLink_5dd2 {
  float: left;
}
