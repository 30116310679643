.FeatureContent_features_5492 {
  position: absolute;
  top: -50px;
  right: 50px;
  max-width: 480px;
  width: 35%;
  background-color: #121821;
  border-radius: 6px;
}

.FeatureContent_features__details_4924 {
  padding: 40px;
}

.FeatureContent_detailRow_67c8 {
  display: flex;
}

.FeatureContent_detailRow__icon_c155 {
  height: 25px;
  align-self: center;
}

.FeatureContent_detailRow__header_fe5f {
  font-size: 20px;
  margin-bottom: 15px;
}

.FeatureContent_detailRow__text_5ea6 {
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 10px;
}

.FeatureContent_featuresContainer_b718 {
  position: relative;
  background-color: #fff;
  padding: 50px;
}

.FeatureContent_featureCta_f106 {
  background-color: #fd1e6e;
  height: 55px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.FeatureContent_featureContentTitle_5b11 {
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 18px;
  color: #121821;
  width: 58%;
  font-family: 'Nexa Bold', sans-serif;
}

.FeatureContent_featureContent_0eb5 {
  color: #121821;
  font-size: 16px;
  line-height: 21px;
  width: 58%;
  white-space: pre-wrap;
}

.FeatureContent_featureSellingPointMarker_86f0 {
  height: 12.5px;
  width: 17.38px;
  margin-right: 18.62px;
}

.FeatureContent_featureCtaButton_20ef {
  height: 100%;
  color: white !important;
  font-size: 15px !important;
}
