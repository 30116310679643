.DesktopVerticalSplitLayout_container_fc65.DesktopVerticalSplitLayout_desktop_0468 {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.DesktopVerticalSplitLayout_left_dc98.DesktopVerticalSplitLayout_desktop_0468,
.DesktopVerticalSplitLayout_right_7fa0.DesktopVerticalSplitLayout_desktop_0468 {
  flex-grow: 1;
  flex-shrink: 0;
  width: 50%;
}
