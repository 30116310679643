.HeaderTopFill_headerTopFill_c006 {
  top: -100px;
  height: 130px;
  width: 100%;
  z-index: 10;
}

.HeaderTopFill_safeAreaTop_d35f {
  height: 150px;
}
