@media only screen and (min-width: 801px) {
  .LoginPromptModal_buttonsContainer_ad57 {
    display: flex
  }
    .LoginPromptModal_buttonsContainer_ad57 > button {
      flex: 1;
      min-width: 170px;
      margin: 0 5px;
    }
}

@media only screen and (max-width: 800px) {
  .LoginPromptModal_middle_22bb {
    padding-top: 8vh !important;
  }

  .LoginPromptModal_buttonsContainer_ad57 {
    width: 100%
  }
    .LoginPromptModal_buttonsContainer_ad57 > button {
      margin: 10px 0;
    }
}

.LoginPromptModal_heading_ca17 {
  font-size: 24px;
  margin-bottom: 50px;
}

.LoginPromptModal_paragraph_8b3e {
  margin-bottom: 40px;
  max-width: 500px;
}

.LoginPromptModal_middle_22bb {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25vh;
  text-align: center;
}

.LoginPromptModal_container_e77d {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.LoginPromptModal_closeIcon_a317 {
  width: 6em !important;
}
