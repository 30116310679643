.FhsFormPage_collapsibleContainer_a300 {
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.FhsFormPage_collapsibleHeader_370b,
.FhsFormPage_collapsibleHeaderOpen_7f59 {
  padding: 10px;
  width: 100%;
  display: block;
  cursor: pointer;
}

.FhsFormPage_collapsibleHeader_370b:after,
.FhsFormPage_collapsibleHeaderOpen_7f59:after {
  content: '^';
  display: inline-block;
  position: absolute;
  right: 20px;
  font-size: 20px;
  font-weight: bold;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.FhsFormPage_collapsibleHeaderOpen_7f59:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.FhsFormPage_collapsibleContent_02a0 {
  padding: 10px;
}

.FhsFormPage_urlOk_5590 {
  font-size: 13px;
  margin-top: -25px;
}

.FhsFormPage_checkIcon_ce5a {
  display: inline-block;
  width: 20px !important; /* To override <SvgIcon> 1em width */
  height: 20px;
  margin-right: 10px;
  vertical-align: middle
}

.FhsFormPage_checkIcon_ce5a path {
    fill: currentColor;
  }
