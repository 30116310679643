.HomePage_twoButtons_ac41 {
  margin-bottom: 45px;
}

@media only screen and (max-width: 800px) {
  .HomePage_middle_c64c {
    /* @note match with top height */
    margin-top: 65px;
  }
  .HomePage_footer_df6b {
    height: 215px;
  }
  .HomePage_secondButton_9a7c {
    padding-top: 20px !important;
    padding-bottom: 0 !important;
  }
}

.HomePage_middleContainer_2c8b {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
}

.HomePage_middle_c64c {
  background-color: #f0f0f0;
}

.HomePage_divider_ffa1 {
  margin-top: 70px;
  margin-bottom: 53px;
  text-align: center;
}

.HomePage_contentContainer_027b {
  height: 480px;
  display: flex;
  margin-top: 50px;
}

.HomePage_newsCarouselContainer_e381 {
  padding-right: 10px;
  box-sizing: border-box;
}

.HomePage_mobileDivider_f8c2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #121821;
  height: 55px;
}

.HomePage_dividerHeading_5888 {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 12px;
}

.HomePage_dividerText_9ce9 {
  font-size: 18px;
  line-height: 21px;
}

.HomePage_buttonWrapper_9a41 {
  padding: 45px
}

.HomePage_buttonWrapper_9a41 > .HomePage_button_ee25 {
    background-color: #fd1e6e;
    color: rgba(255, 255, 255, 0.92);
  }

.HomePage_buttonPadding_724b:first-child{
    padding: 45px 45px 0px 45px;
}

.HomePage_mobileContent_95bc {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.HomePage_blockedModalHeader_b02b {
  text-align: center;
  margin-top: 5vh;
}

.HomePage_blockedModalText_b106 {
  text-align: center;
  margin-bottom: 11vh;
}

.HomePage_articleCarouselContainer_637c {
  height: 100%;
}

.HomePage_mobileArticleCarouselContainer_c2e4 {
  height: 37vh; /* Needs to be the same as Carousel component's .articleBox CSS class */
}

@media only screen and (min-width: 801px) {
  .HomePage_middle_c64c {
    /* @note match with top height */
    margin-top: 85px;
  }
  .HomePage_twoButtons_ac41 {
    display: flex;
    flex-direction: row;
  }
}
