@media only screen and (max-width: 800px) {
  .index_middle_d187 {
    /* @note match with top height */
    margin-top: 65px;
  }
  .index_footer_7e42 {
    height: 215px;
  }
}

@media only screen and (min-width: 801px) {
  .index_middle_d187 {
    /* @note match with top height */
    margin-top: 85px;
  }
}

.index_middle_d187 {
  background-color: #f0f0f0;
}

.index_discountsBanner_8ffa {
  height: 50px;
  line-height: 50px;
  color: #121821;
  background-color: #feed01;
}

.index_buttonWrapper_b22b {
  padding: 45px
}

.index_buttonWrapper_b22b > .index_button_80c8 {
    background-color: #fd1e6e;
    color: rgba(255, 255, 255, 0.92);
  }

/* Modals */

.index_modalContainer_0a46 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.index_modalHeader_5a9b {
  font-size: 24px;
}

.index_modalButtonsContainer_4d85 {
  margin-bottom: 40px;
}

@media only screen and (min-width: 801px) {
  .index_modalHeader_5a9b {
    margin-top: 25vh;
    margin-bottom: 50px;
  }

  .index_modalText_e5be {
    margin-bottom: 40px;
  }

  .index_modalButtonsContainer_4d85 {
    display: flex
  }
    .index_modalButtonsContainer_4d85 > button {
      flex: 1;
      min-width: 200px;
      margin: 0 5px;
    }
}

@media only screen and (max-width: 800px) {
  .index_modalHeader_5a9b {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .index_modalText_e5be {
    margin-bottom: 20px;
  }

  .index_modalButtonsContainer_4d85 {
    width: 100%
  }
    .index_modalButtonsContainer_4d85 > button {
      margin: 10px 0;
    }
}
