.index_h1_6b94 {
  margin-top: 43px;
  margin-bottom: 35px;
}

.index_paragraph_44d1 {
  font-size: 13px;
  margin-bottom: 41px;
}

.index_selectorContainer_6177 {
  display: flex;
  align-items: center;
  min-width: 80px;
  font-size: 13px;
  padding-left: 20px;
}

.index_onboardingHeader_70c9 {
  height: 40px;
}
