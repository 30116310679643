.FhsAfterFailurePage_check_4227 {
  width: 146px;
  height: 140px;
  margin-bottom: 2em;
}

.FhsAfterFailurePage_message_5926 {
  margin-top: 1em;
}

.FhsAfterFailurePage_button_ba4e {
  margin-top: 90px;
  margin-bottom: 10px;
}
