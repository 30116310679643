.AuthAfterFailurePage_check_a2a8 {
  width: 146px;
  height: 140px;
  margin-bottom: 2em;
}

.AuthAfterFailurePage_message_2d64 {
  margin-top: 1em;
}

.AuthAfterFailurePage_button_fc97 {
  margin-top: 90px;
  margin-bottom: 10px;
}
