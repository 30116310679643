.LogoContainer_logoContainer_6cb4 {
  display: flex;
  justify-content: space-evenly;
  height: 33px;
  width: 100%;
}

.LogoContainer_logo_25d1 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
