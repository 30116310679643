.BarcodeView_barcodeContainer_9dae {
  padding: 20px 20px 70px 20px;
  padding-top: calc(25px + constant(safe-area-inset-top)); /* iOS 11.0 */
  padding-top: calc(25px + env(safe-area-inset-top)); /* iOS 11.2 */
  padding-bottom: calc(50px + constant(safe-area-inset-bottom)); /* iOS 11.0 */
  padding-bottom: calc(50px + env(safe-area-inset-bottom)); /* iOS 11.2 */
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.BarcodeView_noIsic_7dfb {
  border-radius: 10px;
  background-color: #f8f8f8;
  opacity: 0.28;
  border: 2px dashed #000000;
}

.BarcodeView_isicText_35c1 {
  text-align: center;
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.BarcodeView_isicNumber_107b {
  text-align: center;
  color: black;
  font-size: 14px;
}

.BarcodeView_noIsicText_8ef4 {
  text-align: center;
  color: black;
  font-size: 13px;
}

.BarcodeView_membership_c9c4 {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px;
  height: 82px;
}

.BarcodeView_background-white_49b3 {
  background-color: #eeeeee;
}

.BarcodeView_background-blue_8d12 {
  background-color: rgba(248, 248, 248, 0.2);
}

.BarcodeView_background-green_cc7b {
  background-color: rgba(248, 248, 248, 0.2);
}

.BarcodeView_isic_bad7 {
  border-radius: 10px;
}

.BarcodeView_barcodeSvgContainer_28b3 {
  width: 100%;
  display: flex;
  height: 62px;
  align-items: center;
}

.BarcodeView_isicContainer_6779 {
  width: 100%;
  display: flex;
  padding: 5px;
  height: 82px;
  align-items: center;
}

.BarcodeView_isicLogo_819a {
  max-width: 130px;
  margin-left: 5px;
  margin-right: 5px;
}

.BarcodeView_isicTextContainer_c28a {
  flex: auto;
  align-self: center;
}
