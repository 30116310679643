.RandomOfferDialog_wrapper_e591 {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 50px;
  background-color: #121821;
  font-size: 20px;
  text-align: center;
  line-height: 26px;
  cursor: default;
  z-index: 20
}
.RandomOfferDialog_wrapper_e591 > * {
    margin-top: 12vh; 
    width: 100%;
  }
