.KoskiFormPage_icon_4460 {
  margin-top: 20px;
  margin-bottom: 64px;
}

.KoskiFormPage_p_97a7 {
  margin-bottom: 20px;
}

.KoskiFormPage_problems_d541 {
  margin: 3em *;
}
