.FadeToDirection_fadeToDirection_7566 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.FadeToDirection_fade-in-left_bee6 {
  -webkit-animation-name: FadeToDirection_fadeInLeft_ba26;
          animation-name: FadeToDirection_fadeInLeft_ba26;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
}

.FadeToDirection_fade-out-left_2ac4 {
  -webkit-animation-name: FadeToDirection_fadeOutLeft_e817;
          animation-name: FadeToDirection_fadeOutLeft_e817;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes FadeToDirection_fadeInLeft_ba26 {
  0% {
    -webkit-transform: translateX(-250%);
            transform: translateX(-250%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes FadeToDirection_fadeInLeft_ba26 {
  0% {
    -webkit-transform: translateX(-250%);
            transform: translateX(-250%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes FadeToDirection_fadeOutLeft_e817 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-250%);
            transform: translateX(-250%);
  }
}

@keyframes FadeToDirection_fadeOutLeft_e817 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-250%);
            transform: translateX(-250%);
  }
}

.FadeToDirection_fade-in-right_5553 {
  -webkit-animation-name: FadeToDirection_fadeInRight_0495;
          animation-name: FadeToDirection_fadeInRight_0495;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
}

.FadeToDirection_fade-out-right_d750 {
  -webkit-animation-name: FadeToDirection_fadeOutRight_8b56;
          animation-name: FadeToDirection_fadeOutRight_8b56;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes FadeToDirection_fadeInRight_0495 {
  0% {
    -webkit-transform: translateX(250%);
            transform: translateX(250%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes FadeToDirection_fadeInRight_0495 {
  0% {
    -webkit-transform: translateX(250%);
            transform: translateX(250%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes FadeToDirection_fadeOutRight_8b56 {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(250%);
            transform: translateX(250%);
  }
}

@keyframes FadeToDirection_fadeOutRight_8b56 {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(250%);
            transform: translateX(250%);
  }
}
