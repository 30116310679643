.EditPhoneNumberPage_editPhoneNumberForm_9cf1 {
  transition: all 0.3s ease-out;
  margin: 35px 0 15px 0;
}

.EditPhoneNumberPage_editPhoneNumberContainer_aabb {
  padding: 120px 25px 60px 25px;
}

.EditPhoneNumberPage_editPhoneNumberContainer_aabb h1 {
  line-height: 1.1;
}

.EditPhoneNumberPage_editPhoneNumberContainer_aabb h3 {
  font-size: 16px;
  color: rgb(169, 169, 169);
  margin: 35px 0 15px 0;
}

.EditPhoneNumberPage_button_c296 {
  padding: 10px 34px 11px 34px !important;
}

.EditPhoneNumberPage_did-not-get-verification-message_5479 {
  font-size: 10pt;
  margin-bottom: 15px;
}

.EditPhoneNumberPage_disclaimerElement_ca1d {
  margin-top: 15px;
  margin-bottom: 20px;
}
