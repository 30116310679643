.FhsAfterSuccessPage_check_cc2d {
  width: 146px;
  height: 140px;
  margin-bottom: 2em;
}

.FhsAfterSuccessPage_message_77f0 {
  margin-top: 1em;
}

.FhsAfterSuccessPage_button_13ac {
  margin-top: 90px;
  margin-bottom: 10px;
}
