@media only screen and (max-width: 800px) {
  .styles_content_d33e.styles_stretch_ee4c {
    display: flex;
    flex-direction: column;
  }
}

.styles_button_c39e {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  margin: 0.5em;
}

.styles_button-container_5dcd {
  display: flex;
  justify-content: center;
}

.styles_push_7cb9 {
  margin: 0 5px;
}

.styles_content_d33e p {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.styles_content_d33e p,
  .styles_content_d33e p * {
    white-space: pre-wrap;
  }

.styles_summaryListItem_182d {
  display: flex;
  justify-content: space-between
}

.styles_summaryListItem_182d > p {
    margin: 8.5px 0;
  }

.styles_summaryListItem_182d .styles_summaryListKey_0e56 {
    width: 50%;
  }

.styles_summaryListItem_182d .styles_summaryListValue_d794 {
    width: 50%;
  }

.styles_summaryListItem_182d img {
    margin: 8.5px 0;
    width: 80px;
    height: 80px;
  }

.styles_summaryDivider_b43e {
  box-sizing: border-box;
  border: 1px solid #ffffff !important;
  opacity: 0.26;
}

.styles_singleButton_c28e {
  text-align: center;
  width: 100%;
  padding-left: inherit;
  padding-right: inherit;
}

.styles_horizontalButtons_0eb7 {
  display: flex;
  flex-direction: row
}

.styles_horizontalButtons_0eb7 .styles_left_b9eb,
  .styles_horizontalButtons_0eb7 .styles_right_311e {
    width: 100%;
  }

.styles_horizontalButtons_0eb7 .styles_left_b9eb .styles_actionDescription_bfc6,
    .styles_horizontalButtons_0eb7 .styles_left_b9eb .styles_horizontalButton_96e3 {
      float: right;
    }

.styles_horizontalButtons_0eb7 .styles_left_b9eb {
    margin-right: 5px;
  }

.styles_horizontalButtons_0eb7 .styles_right_311e {
    margin-left: 5px;
  }

.styles_actionDescription_bfc6,
.styles_horizontalButton_96e3 {
  width: 146px;
}

.styles_horizontalButton_96e3 {
  height: 58.67px;
  padding: 0.5em !important;
}

.styles_actionDescription_bfc6 {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
}

.styles_terms_0a81 {
  margin-top: 40px;
  margin-bottom: 60px
}

.styles_terms_0a81 .styles_term_3fa2:last-of-type {
    margin-bottom: 40px;
  }

.styles_line_3935 {
  border-color: #fff;
  opacity: 0.18;
  margin-right: 10px;
  margin-left: 9px;
}

.styles_heading_1b1a {
  font-size: 30px;
  line-height: 34px;
}

.styles_paragraph_3ba5,
.styles_summaryParagraph_7a60 {
  font-size: 20px;
  line-height: 24px;
}

.styles_summaryParagraph_7a60 {
  margin-bottom: 57px !important;
}

.styles_smallPrint_f828 {
  font-size: 12px;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.styles_textInputLabel_d0de {
  color: #fff !important;
  font-size: 13px !important;
}

.styles_textInputField_20c4:before {
  background: #fff !important;
}

.styles_subtitle_d5b1 {
  font-weight: bold;
}
