@media only screen and (min-width: 801px) {
  .grid_container_1db9 {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .grid_columnLarge1_7a1f {
    max-width: calc(100% / 12);
    flex: 0 0 calc(100% / 12);
  }
  .grid_columnLarge2_1c44 {
    max-width: calc(100% / 12 * 2);
    flex: 0 0 calc(100% / 12 * 2);
  }
  .grid_columnLarge3_3697 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .grid_columnLarge4_f642 {
    max-width: calc(100% / 12 * 4);
    flex: 0 0 calc(100% / 12 * 4);
  }
  .grid_columnLarge5_b656 {
    max-width: calc(100% / 12 * 5);
    flex: 0 0 calc(100% / 12 * 5);
  }
  .grid_columnLarge6_545d {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .grid_columnLarge7_7a6b {
    max-width: calc(100% / 12 * 7);
    flex: 0 0 calc(100% / 12 * 7);
  }
  .grid_columnLarge8_f926 {
    max-width: calc(100% / 12 * 8);
    flex: 0 0 calc(100% / 12 * 8);
  }
  .grid_columnLarge9_6744 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .grid_columnLarge10_a2d0 {
    max-width: calc(100% / 12 * 10);
    flex: 0 0 calc(100% / 12 * 10);
  }
  .grid_columnLarge11_fe5b {
    max-width: calc(100% / 12 * 11);
    flex: 0 0 calc(100% / 12 * 11);
  }
}

@media only screen and (max-width: 800px) {
  .grid_columnLarge1_7a1f,
  .grid_columnLarge2_1c44,
  .grid_columnLarge3_3697,
  .grid_columnLarge4_f642,
  .grid_columnLarge5_b656,
  .grid_columnLarge6_545d,
  .grid_columnLarge7_7a6b,
  .grid_columnLarge8_f926,
  .grid_columnLarge9_6744,
  .grid_columnLarge10_a2d0,
  .grid_columnLarge11_fe5b {
    width: 100%;
  }
}

.grid_space-between_d199 {
  justify-content: space-between;
}

.grid_align-self-center_685b {
  align-self: center;
}

.grid_container_1db9 {
  width: 100%;
}

.grid_row_a2c6 {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}

.grid_column_ab41 {
  display: flex;
  flex: 1;
}

.grid_columnSmall6_d39d {
  max-width: calc(100% / 12 * 6);
  flex: 0 0 calc(100% / 12 * 6);
}

.grid_columnSlide_d635 {
  display: flex;
  flex: 1;
  max-height: 30;
}
