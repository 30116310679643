.VerifyStudentStatusPromptModal_icon_bf05 {
  width: 163px;
  height: 51px;
  padding-top: 88px;
  padding-right: 20px;
  padding-bottom: 88px;
}

.VerifyStudentStatusPromptModal_message_7b95 {
  margin-top: 2em;
}

.VerifyStudentStatusPromptModal_button_8eeb {
  margin-top: 90px;
}
