.LogoutMenu_menuWrapper_9f3b {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 50px;
  background-color: #121821;
  font-size: 24px;
  text-align: center;
  cursor: default;
}

.LogoutMenu_menu_a0a8 {
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px
}

.LogoutMenu_menu_a0a8 > * {
    width: 100%;
    margin-top: 20px;
  }

.LogoutMenu_label_5cfb {
  margin-bottom: 30px;
}

.LogoutMenu_closeIcon_b583 {
  position: absolute;
  width: 23px;
  height: 23pz;
  top: 20px;
  right: 32px;
  cursor: pointer;
}
