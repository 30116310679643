@-webkit-keyframes HeroArticle_fadein_7c01 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes HeroArticle_fadein_7c01 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1400px) {
  .HeroArticle_title_4408 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 15px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .HeroArticle_teaser_ad1f {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 10px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .HeroArticle_link_a5e1 {
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
  }
}

@media only screen and (min-width: 1400px) {
  .HeroArticle_title_4408 {
    font-size: 55px;
    line-height: 65px;
    margin-bottom: 15px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .HeroArticle_teaser_ad1f {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 19px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  }

  .HeroArticle_link_a5e1 {
    cursor: pointer;
    text-decoration: underline;
  }
}

.HeroArticle_fadeIn_76df {
  -webkit-animation: HeroArticle_fadein_7c01 1s;
          animation: HeroArticle_fadein_7c01 1s;
}

.HeroArticle_imageContainer_872b {
  position: relative;
  width: 100%;
  height: 38vw;
  background-size: cover;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.HeroArticle_textContainer_702f {
  top: 50%;
  position: absolute;
  padding-left: 60px;
  box-sizing: border-box;
  display: flex
}

.HeroArticle_textContainer_702f > div {
    align-self: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 7vw;
  }
