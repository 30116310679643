.AuthAfterSuccessPage_check_0e49 {
  width: 146px;
  height: 140px;
  margin-bottom: 2em;
}

.AuthAfterSuccessPage_message_4654 {
  margin-top: 1em;
}

.AuthAfterSuccessPage_button_edc4 {
  margin-top: 90px;
  margin-bottom: 10px;
}
