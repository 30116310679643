.Payment_payment_f22f {
  margin-top: 28px;
  margin-bottom: 28px;
}

@media only screen and (max-width: 800px) {
  .Payment_content_4d3e.Payment_stretch_3114 {
    width: 100%;
  }
}

@media only screen and (min-width: 801px) {
  .Payment_content_4d3e.Payment_stretch_3114 {
    width: 100%;
  }
}
